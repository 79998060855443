import React, {useState, useEffect} from 'react';
import contractAddresses from "../contracts/old-contracts-address.json";
import {Link, useLocation} from "react-router-dom";

import opensea from "../assets/svgs/opensea.svg"
import discord from "../assets/svgs/discord.svg"
import twitter from "../assets/svgs/twitter.svg"
import cx from "classnames";

type IFooterProps = {}

const defaultProps = {}

const Footer: React.FC<IFooterProps> = (props) => {
  const {} = props;
  let location = useLocation();

  const [path, setPath] = useState(location.pathname);

  useEffect(() => {
    setPath(location.pathname)
  }, [location]);

  return (
    <React.Fragment>
      <div className={cx("w-full bottom-0 p-4 md:h-10 flex flex-col md:flex-row md:justify-between justify-center items-center", path?.includes('stills') || path?.includes('about') || path?.includes('how') ? "relative" :  "md:absolute")}>

        <div className="relative md:hidden w-full md:text-left flex justify-center md:justify-start">
          <Link className="text-xs px-6 py-2 m-2 border border-black" to={"/stills"} >STILL(s)</Link>
        </div>

        <div className="md:hidden relative w-full md:text-left flex justify-center md:justify-start my-2">
          <a className="text-xs h-5"
             target={"_blank"} href={"https://discord.gg/c8SKE9uv"}
          >
            <img className="h-full p-1" src={discord} alt={"discord"}/>
          </a>
          <a className="text-xs h-5" href={"https://twitter.com/marco_color_art"} target={"_blank"} >
            <img className="h-full p-1" src={twitter} alt={"twitter"}/>
          </a>
        </div>

        <div className="relative md:hidden w-full md:text-left flex justify-center md:justify-start">
          <Link className="text-xs px-3 py-1 m-2 border rounded-xl" to={"/how"} >How it works</Link>
          <Link className="text-xs px-3 py-1 m-2 border rounded-xl" to={"/about"} >About</Link>
        </div>

        <div className="relative w-full md:text-left flex justify-center md:justify-start">
          <p className="md:absolute md:-top-4 md:left-0 text-xs text-gray-500">All happening on-chain at:</p>
          <a className="md:text-sm text-xs text-gray-700"
             href={"https://etherscan.io/address/" + contractAddresses.Colors}>
            <span className={"md:hidden ml-2 underline"}>{contractAddresses.Colors.substring(0, 6)} ... {contractAddresses.Colors.substring(contractAddresses.Colors.length - 4)}</span>
            <span className={"md:block hidden"}>{contractAddresses.Colors}</span>
          </a>
        </div>


        <div className="flex w-full md:items-end md:justify-end justify-center mt-2 md:mt-0">

          <div className=" h-7 hidden md:flex relative text-right justify-end items-center">

            <a className="text-xs h-6 ml-2"
               target={"_blank"}
               href={"https://opensea.io/collection/marco-colors"}
            >
              <img className="h-full p-1" src={opensea} alt={"discord"}/>
            </a>

            <a className="text-xs h-5 ml-2"
               target={"_blank"}
               href={"https://discord.gg/jAqqPuKc"}
            >
              <img className="h-full p-1" src={discord} alt={"discord"}/>
            </a>

            <a className="text-xs h-5 ml-2"
               href={process.env.REACT_APP_DISCORD}

               target={"_blank"} >
              <img className="h-full p-1" src={twitter} alt={"twitter"}/>
            </a>

          </div>

        </div>
      </div>
    </React.Fragment>
  )
}

Footer.defaultProps = defaultProps;

export default Footer
