import React, {useEffect, useState} from "react";
import {IPixel} from "../../features/pixels/pixelsReducers";
import {useHistory} from "react-router-dom";

type IPixelAtomProps = {
  p: IPixel,
  coordsId: string,
  onMouseEnter: any,
}

const defaultProps = {}

const PixelAtomStill = ({p, coordsId, onMouseEnter}: IPixelAtomProps) => {

  const history = useHistory();
  const [isIn, setIsIn] = useState(false);
  const navigate = (id: string, params: any) => {
    console.log("SEND => ", params)
    history.push("color/" + id, {params});
  }

  useEffect(() => {

  }, []);

  return (
    <React.Fragment>
      <rect
        key={p.positionX.toString() + "_" + p.positionY.toString()}
        id={p.positionX.toString() + "_" + p.positionY.toString()}
        onClick={() => navigate(
          coordsId, p
        )}
        onMouseEnter={() => {
          onMouseEnter(p)
          // setSelectedPixel(p)
          setIsIn(true)
        }}
        onMouseLeave={() => {
          setIsIn(false);
        }}
        strokeWidth="0.03"
        stroke="grey"
        x={p.positionX} y={p.positionY}
        width="0.95" height="0.95"
        fill={!isIn ? "rgb(" + p.colorR + "," + p.colorG + "," + p.colorB + ")" : "black"}
      />
    </React.Fragment>
  )
}

PixelAtomStill.defaultProps = defaultProps;

export default PixelAtomStill;
