import {ContractTypes, IContractActions} from "./contractActions";

/* SELECTORS */

export const getColorsContractProvider = (state: any) => state.contractReducer.colorsContract;
export const getPricesContractProvider = (state: any) => state.contractReducer.pricesContract;
export const getFlashContractProvider = (state: any) => state.contractReducer.flashContract;
export const getNFTAuction = (state: any) => state.contractReducer.nftContract;
export const getRentContractProvider = (state: any) => state.contractReducer.rentContract;
export const getIsColorsLoading = (state: any) => state.contractReducer.isColorsDataLoading;
export const getProvider = (state: any) => state.contractReducer.provider;
export const getTxSentStatus = (state: any) => state.contractReducer.txBeingSent;

/* REDUCER */

export interface IContractState {
  provider: any,
  isColorsDataLoading: boolean,
  colorsContract: any,
  nftContract: any,
  pricesContract: any,
  flashContract: any,
  rentContract: any,
}

export const ContractInitialState: IContractState = {
  provider: null,
  isColorsDataLoading: true,
  colorsContract: undefined,
  nftContract: undefined,
  pricesContract: undefined,
  flashContract: undefined,
  rentContract: undefined,
}

export const contractReducer = (
  state = ContractInitialState,
  action: IContractActions,
) => {
  switch (action.type) {
    case ContractTypes.SET_COLORS_CONTRACT_PROVIDER:
      return {
        ...state,
        colorsContract: action.provider,
      }
    case ContractTypes.SET_PRICES_CONTRACT_PROVIDER:
      return {
        ...state,
        pricesContract: action.provider,
      }
    case ContractTypes.SET_RENT_CONTRACT_PROVIDER:
      return {
        ...state,
        rentContract: action.provider,
      }
      case ContractTypes.SET_CONTRACT_PROVIDER:
      return {
        ...state,
        [action.name]: action.provider,
      }
    case ContractTypes.SET_ETHERS_PROVIDER:
      return {
        ...state,
        provider: action.provider,
      }
    case ContractTypes.IS_COLORS_LOADING:
      return {
        ...state,
        isColorsDataLoading: action.isLoading,
      }
    default:
      return state
  }
}
