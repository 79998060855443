import React, {useState, useEffect, useRef} from 'react';
import styles from "./pixels.module.scss"
import Pixelsbuilder from "../PixelsBuilder/PixelsBuilder";
import PixelsRemaining from "../PixelsRemaining/PixelsRemaining";
import PixelsSidePanel from "../SidePanel/SidePanel";
import cx from "classnames";
import InfoPanel from "../LeftPanel/InfoPanel";
import contractAddresses from "../../contracts/old-contracts-address.json";
import {Link} from "react-router-dom";
import {BuildHelmet} from "../HelmetBuilder";
import ReactGA from "react-ga4";


type IPixelsProps = {}

const Pixels: React.FC<IPixelsProps> = () => {

  const contract = contractAddresses.Colors;
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return (
    <>
      <article className={styles.mainContainer}>

        {/*LEFT PANEL*/}
        <div className={"pt-8 w-full relative" +
          " md:w-1/4 lg:w-1/4 xl:w-1/4 md:absolute md:top-24 md:left-0 md:pt-0 md:h-4/5"}>
          <InfoPanel/>
        </div>

        {/*PIXELS PANEL*/}
        <div className={cx(styles.pixelsContainer, "")}>
          <div className="flex flex-col relative">
            <div className={cx(
              styles.pixelsHeaderContainer,
              "flex justify-center w-full items-center h-20"
            )}>
              {/*<Pricetracker/>*/}
              {/*<Blockstonextprice/>*/}
              <PixelsRemaining/>
            </div>
            <Pixelsbuilder/>
          </div>
        </div>


        {/*SIDE PANEL*/}
        <section className={cx(styles.sideRightPanelContainer,
          "md:w-1/5 md:absolute md:top-20 md:right-0 md:w-1/5" +
          " lg:w-1/6 md:border-l-2 md:border-gray-200"
        )}>
          <PixelsSidePanel/>
        </section>

      </article>
    </>
  )
}

Pixels.defaultProps = {}

export default Pixels
