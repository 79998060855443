import {RootState} from "../../store/indexReducers";
import {MetamaskService} from "../../services/metamaskService";
import {PixelsTypes, IPixelsActions} from "./pixelsActions";
import {PixelService} from "../../services/pixelService";

/* SELECTORS */

export const getPixels = (state: any) => state.pixelsReducer.pixels;
export const getPixelPrice = (state: any) => state.pixelsReducer.pixelPrice;
export const getMaxColors = (state: any) => state.pixelsReducer.max_pixels;

/* REDUCER */

export interface IPixel {
  tokenId?: number | null,
  isMinted?: boolean | undefined,
  colorR?: number | undefined,
  colorG?: number | undefined,
  colorB?: number | undefined,
  positionX: number,
  positionY: number,
  owner?: string | undefined,
}

export interface IPixelsState {
  pixels: {
    [key: string]: IPixel
  };
  max_pixels: number;
  pixelPrice: number | null;
}

export const PixelsInitialState: IPixelsState = {
  pixels: PixelService.buildInitialPixels(),
  max_pixels: 0,
  pixelPrice: null
}

export const pixelsReducer = (
  state = PixelsInitialState,
  action: IPixelsActions,
) => {
  switch (action.type) {
    case PixelsTypes.SET_PIXELS:
      return {
        ...state,
        pixels: action.pixels,
      }
    case PixelsTypes.UPDATE_PIXEL:
      let pixels = {...state.pixels}
      // pixels[action.pixel.tokenId] = action.pixel;
      return {
        ...state,
        pixels: pixels
      }
    case PixelsTypes.UPDATE_PIXELS:
      return {
        ...state,
        pixels: {
          ...state.pixels
        }
      }
    case PixelsTypes.SET_MAX_PIXELS:
      return {
        ...state,
        max_pixels: action.max_pixels
      }
    case PixelsTypes.SET_PRICE:
      return {
        ...state,
        pixelPrice: action.price
      }
    default:
      return state
  }
}
