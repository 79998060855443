import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
  getColorsContractProvider,
  getFlashContractProvider,
  getNFTAuction
} from "../../features/contract/contractReducers";
import styles from "./stills.module.scss";
import PixelsBuilderStill from "../PixelsBuilderStill/PixelsBuilderStill";
import cx from "classnames";
import Moment from "react-moment";
import Countdown from "react-countdown";
import ReactGA from "react-ga4";


type IFlashesProps = {}

const defaultProps = {}

const Stills: React.FC<IFlashesProps> = (props) => {
  const {} = props;

  const [datesLocal, setDatesLocal] = useState([]);

  const colorsContract = useSelector(getColorsContractProvider);
  const flashContract = useSelector(getFlashContractProvider);
  const nftContract = useSelector(getNFTAuction);

  const mockStills = Array.from(Array(12).keys());
  const y = 365 * 24 * 60 * 60;
  const m = 27 * 24 * 60 * 60;
  const times = [m, y, y * 2, y * 3, y * 4, y * 5, y * 6, y * 7, y * 8, y * 9, y * 10, y * 100]
  const [stillsWrapper, setStillsWrapper] = useState<any[]>(mockStills);

  const loadData = async () => {
    if (!colorsContract && !flashContract) {
      return;
    }

    /*
        const startBlock = 0;
        const endBlock = 20000;
        let allEvents: any[] = [];

        for (let i = startBlock; i < endBlock; i += 5000) {
          const _startBlock = i;
          const _endBlock = Math.min(endBlock, i + 4999);
          const events = await colorsContract.queryFilter("Flash", _startBlock, _endBlock);
          allEvents = [...allEvents, ...events]
        }
        */

    const mintEvents = colorsContract.filters.MintCOLOR(null, null, null);
    const events = await colorsContract.queryFilter("MintCOLOR", 1, 15282)
    // console.log('EVENTS MintCOLOR => ', allEvents);
    console.log('EVENTS => ', mintEvents);

    // const dates = await flashContract.getDates();
    // console.log('FLASH Dates => ', dates);
    // setDatesLocal(dates);
  }

  const makePhoto = async () => {
    console.log('photo');
    await colorsContract.makePhoto();
  }

  const getAuction = async () => {
    // console.log('auction');
    // const auction = await nftContract.nftContractAuctions(contractAddresses.Flash, 0);
    // console.log(auction);
  }
  const convertTime = (value: number) => {
    return <Moment duration={new Date()}
                   date={Date.now() + value * 1000}/>
  }

  useEffect(() => {
    loadData();
  }, [colorsContract, flashContract]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });    return () => {
    };
  }, []);


  const renderStill = (s: any, i: number) => {
    return (
      <section key={i} className="w-full flex justify-center px-6 md:px-0 md:justify-end mb-20 border-b pb-20 opacity-30">
        <div className={cx(styles.pixelsContainer, "md:mr-10 lg:mr-20")}>

          <div className="md:p-10 flex flex-col items-end w-full md:w-auto mb-6">
            <p className={"text-sm"}>STILL ID:</p>
            <p className={"text-4xl mb-8"}>#{s}</p>
            <p className={"text-sm mb-2"}>OPENS IN:</p>
            <p className={"text-sm mb-4"}>~{convertTime(times[i])}</p>
            <p className={"text-sm mb-2"}>Ongoing auction:</p>
            <p className={"text-sm mb-2"}>0 Ξ</p>
            <button
              className="w-full py-2 px-6 border-2 border-black cursor-default mb-2"
              style={{}}
              onClick={() => {
              }}>
              Open auction
            </button>
            <button
              className="w-full py-2 px-6 border-2 border-black cursor-default mb-12"
              style={{}}
              onClick={() => {
              }}>
              Bid
            </button>
            <button
              className="w-full py-2 px-6 border-2 border-black cursor-default mb-4"
              style={{}}
              onClick={() => {
              }}>
              Add your COLOR
            </button>
            <button
              className="w-full py-2 px-6 border-2 border-black cursor-default "
              style={{}}
              onClick={() => {
              }}>
              Redeem your share
            </button>
          </div>

          <div className="flex flex-col items-center justify-center">
            <PixelsBuilderStill/>
          </div>
        </div>
      </section>
    )
  }

  return (
    <>
      <section className="w-full h-48 bg-grey justify-between items-center relative">
        <div className="absolute -bottom-48 left-4 md:left-1/3 border border-black bg-white shadow flex flex-col md:w-1/3 w-full z-50 p-4">
          <p>A <span className={"text-black font-semibold"}>STILL</span> is an instant of the main art that has been frozen by each of the global art participants. Each participant will have made its <span className={"text-black font-semibold"}>COLOR</span>  tint permanent when the time comes. An NFT will be generated and sold at open auction. The auction income will be distributed to the participants.</p>
          <p className={"text-sm text-gray-500"}>*Coming soon...</p>
{/*          <Countdown className={"text-xl"} date={new Date("01-01-2022")}>
            <p className={"text-xl text-gray-500"}>Price reduction active</p>
          </Countdown>*/}
          <div className="mb-4 mt-4">

            <a target={"_blank"}
               href={process.env.REACT_APP_DISCORD} className="hover:shadow text-xs px-3 py-1 mt-2 border border-black rounded-xl bg-white">
              Get in <span className="text-gray-500">(Discord)</span>
            </a>

            <a className="text-xs px-3 py-1 mt-2 ml-2 border border-black rounded-xl bg-white h-5 w-auto hover:shadow"
               href={"https://twitter.com/metamarco_nft"}
               target={"_blank"}>
              Follow <span className="text-gray-500">
              (twitter)
            </span>
            </a>

          </div>
        </div>
      </section>

      <section className="absolute top-28 right-10 flex flex-col md:flex-row items-center text-right">
        <p className={"text-sm mr-6"}>Minted STILL(s)</p>
        <p className={"text-3xl items-center flex"}>
          #0/11
        </p>
      </section>

      {/*PIXELS PANEL*/}
      {
        stillsWrapper.map((still, index) => {
          return renderStill(still, index);
        })
      }
    </>
  )
}

Stills.defaultProps = defaultProps;

export default Stills
