import React, {useState, useEffect} from 'react';
import styles from "./switch.module.scss"

type ISwitchProps = {
  isChecked: boolean,
  handleChangeChk: any
}

const defaultProps = {}

const Switch: React.FC<ISwitchProps> = (props) => {
  const {isChecked, handleChangeChk} = props;
  const [isCheckedLocal, setIsCheckedLocal] = useState(false);

  useEffect(() => {
    handleChangeChk(isCheckedLocal)
  }, [isCheckedLocal]);

  return (
    <React.Fragment>
      <label className={styles.switch}>
        <input
          className={styles.input}
          type="checkbox"
          checked={isChecked}
          onChange={(event) => setIsCheckedLocal(!isCheckedLocal)}
        />
        <span className={styles.slider}/>
      </label>
    </React.Fragment>
  )
}

Switch.defaultProps = defaultProps;

export default Switch
