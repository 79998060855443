import React, {useEffect, useState} from "react";
import {IPixel} from "../../features/pixels/pixelsReducers";
import {useHistory} from "react-router-dom";
import ReactGA from "react-ga4";

type IPixelAtomProps = {
  p: IPixel,
  coordsId: string,
  onMouseEnter: any,
}

const defaultProps = {}

const PixelAtom = ({p, coordsId, onMouseEnter}: IPixelAtomProps) => {

  const history = useHistory();
  const [isIn, setIsIn] = useState(false);
  const navigate = (id: string, params: any) => {
    console.log("SEND => ", params);
    ReactGA.event({
      category: "COLOR",
      action: "COLOR click",
      label: id, // optional
    });
    history.push("color/" + id, {params});
  }

  useEffect(() => {

  }, []);

  return (
    <React.Fragment>
      <rect
        key={p.positionX.toString() + "_" + p.positionY.toString()}
        id={p.positionX.toString() + "_" + p.positionY.toString()}
        onClick={() => navigate(
          coordsId, p
        )}
        onMouseEnter={() => {
          onMouseEnter(p)
          // setSelectedPixel(p)
          setIsIn(true)
        }}
        onMouseLeave={() => {
          setIsIn(false);
        }}
        strokeWidth="0.03"
        stroke={p.isMinted ? "black" : "lightGrey"}
        x={p.positionX} y={p.positionY}
        width="0.97" height="0.97"
        fill={!isIn ? "rgb(" + p.colorR + "," + p.colorG + "," + p.colorB + ")" : "black"}
      />
    </React.Fragment>
  )
}

PixelAtom.defaultProps = defaultProps;

export default PixelAtom;
