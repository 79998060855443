import React, {useEffect, useState} from 'react';
import {getPixels, IPixel} from "../../features/pixels/pixelsReducers";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import PixelAtomStill from "./PixelAtomStill";
import baseStill from "../../assets/svgs/baseStill.svg";

export type PixelsSvgProps = {
  allPixels: any,
  onMouseEnter: any
}

const PixelsSvgStill = (props: { allPixels: any; onMouseEnter: any; }) => {

  const {allPixels, onMouseEnter} = props;
  const history = useHistory();


  useEffect(() => {
  }, [allPixels]);

  useEffect(() => {
  }, []);

  const svgPixels = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      {
        Object.entries<IPixel>(allPixels).map(([coords, p]) => {
          const coordsId = p.positionX.toString() + "_" + p.positionY.toString();
          return <PixelAtomStill
            p={p}
            onMouseEnter={onMouseEnter}
            key={p.positionX.toString() + "_" + p.positionY.toString()}
            coordsId={coords}
          />
        })
      }
    </svg>)
  }

  const setPixel = (p: React.SetStateAction<IPixel | undefined>) => {
    console.log(p)
    // setSelectedPixel(p);
  }

  return (
    <>
      {/*{svgPixels()}*/}
      <img className={""} src={baseStill} alt={""}/>
    </>
  );
}

// @ts-ignore
export default React.memo(PixelsSvgStill)


