import React, {useState, useEffect} from 'react'; // we need this to make JSX compile
import {ChromePicker} from 'react-color';
import styles from "./colorSet.module.scss"
import cx from "classnames";

type IColorSetterProps = {
  color: any;
  updateColor: any;
  action?: any;
  close?: any
}

const defaultProps = {}

const ColorSetter: React.FC<IColorSetterProps> = (props) => {
  const {color, updateColor, action, close} = props;

  useEffect(() => {

  }, []);

  return (
    <div className={styles.colorSetterWrapper}>
      <div>
        <div className={styles.colorHeader}>
          <p className={"text-sm pb-3"}>1. Select the first color → 2.Mint your COLOR → 3. Congrats.</p>
          <p className="cursor-pointer"
             onClick={() => {
               close();
             }}>X</p>
        </div>
        <div className={styles.pickerWrapper}>
          <ChromePicker
            disableAlpha
            color={color}
            onChange={(color, e) => {
              // console.log('COLOR => ', color)
              updateColor(color, e)
            }}
          />
        </div>
        <div className={styles.colorSetterText}>
          {color && <>

              <div className="flex items-center">
                  <p className="text-sm mr-2 text-gray-500 py-2">SELECTED COLOR:</p>
                  <p className="text-sm flex align-middle h-full pr-2">
                      <span className="text-gray-500">RGB: </span> {color.rgb.r}, {color.rgb.g}, {color.rgb.b}
                  </p>
              </div>

              <div className={styles.colorBox} style={{
                backgroundColor: "rgb(" +
                  color?.rgb.r + "," +
                  color?.rgb.g + "," +
                  color?.rgb.b + ")"
              }}></div>

            {/*MANUAL SET UP*/}
              <div className="mt-2 items-center justify-start">
                  <p className="text-xs mr-2 text-gray-500 pb-2">Or manually, RGB:</p>
                {/*R*/}
                  <div className="flex items-center">
                      <p className="text-xs text-gray-500 pr-1">R:</p>
                  <input
                      className={
                        cx("border px-1 rounded text-center text-xs mr-1")
                      } type={'number'} min="0" max="255"
                      onChange={e => {
                        let r = parseInt(e.target.value);
                        r = r > 255 ? 255 : r;
                        const colorNew = {...color}
                        colorNew.rgb.r = r;
                        updateColor(colorNew)
                      }}
                      value={
                        color.rgb.r
                      }
                  />
                {/*G*/}
                      <p className="text-xs text-gray-500 pr-1">G:</p>
                  <input
                      className={
                        cx("border px-1 rounded text-center text-xs mr-1")
                      }
                      type={'number'}
                      min="0"
                      max="255"
                      onChange={e => {
                        let g = parseInt(e.target.value);
                        g = g > 255 ? 255 : g;
                        const colorNew = {...color}
                        colorNew.rgb.g = g;
                        updateColor(colorNew)
                      }}
                      value={
                        color.rgb.g
                      }
                  />
                {/*B*/}
                      <p className="text-xs text-gray-500 pr-1">B:</p>
                  <input
                      className={
                        cx("border px-1 rounded text-center text-xs")
                      }
                      type={'number'}
                      min="0"
                      max="255"
                      onChange={e => {
                        let b = parseInt(e.target.value);
                        b = b > 255 ? 255 : b;
                        const colorNew = {...color}
                        colorNew.rgb.b = b;
                        updateColor(colorNew)
                      }}
                      value={
                        color.rgb.b
                      }
                  />
                  </div>
              </div>
          </>
          }
          <p className="text-sm py-2">
            Once you are sure about your COLOR tint.
            Just hit Mint COLOR to own it. Ownership allows you to change the color whenever you want.
          </p>
        </div>
      </div>
      <div className="py-3">
        <p className="text-xs pb-1 text-right text-gray-500">Your wallet will be prompt</p>
        <button
          className="px-10 py-2 border-2 border-black shadow cursor-pointer w-full"
          onClick={() => action()}
        >Mint COLOR
        </button>
      </div>
    </div>
  )
}

ColorSetter.defaultProps = defaultProps;

export default ColorSetter
