import React, {useState, useEffect} from 'react';
import InfoPanel from "./LeftPanel/InfoPanel";
import ReactGA from "react-ga4";

import {Link} from "react-router-dom";

type IHowItWorksProps = {}

const defaultProps = {}

const HowItWorks: React.FC<IHowItWorksProps> = (props) => {
  const {} = props;

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

  const b = (text: string) => <span className="text-black font-semibold">{text}</span>;
  return (
    <article className="h-auto w-full">
      <div className="md:left-20 top-20 md:w-2/3 lg:w-1/2 w-full md:p-4 pt-28 md:pb-28 px-4 md:pl-20">
        <div className="md:mt-28">
          <p className="mb-4 pl-10 font-medium">· HOW IT WORKS</p>
          <ul className="text-sm mb-2 text-gray-500">
            <li className="text-sm mb-2 ">
              1. <span className="text-black font-semibold">MARCO</span> is a community-run art meta-NFT project. Any
              change in it has to be triggered by someone.
            </li>
            <li className="text-sm mb-2 ">
              2. Each <span className="text-black font-semibold">COLOR</span> is an <span
              className="text-black font-semibold">NFT</span>, an ERC-721 token with its own art fully held <span
              className="text-black font-semibold">on-chain</span> as an svg in the Ethereum blockchain. Each of these
              tokens can be exchanged freely at any market.
            </li>
            <li className="text-sm mb-2 ">
              3. One wallet, one <span className="text-black font-semibold">COLOR</span>. Each wallet can only own
              one <span className="text-black font-semibold">COLOR</span>.
            </li>
            <li className="text-sm mb-2 ">
              4. By owning a {b("COLOR")} you will be able to change its tint. You will also hold a share of the global
              piece of art.
            </li>
            <li className="text-sm mb-2 ">
              5. Once you own a {b("COLOR")} you may rent it for a specific price and let others change its tint for
              prearranged periods of time.
            </li>
            <li className="text-sm mb-2 ">
              6. Renting {b("COLOR")}s will only be possible after all of them have been sold.
            </li>
            <li className="text-sm mb-2 ">
              7. As a shareholder of the global piece of art you will get dividends from each {b("STILL")} participation.
            </li>
            <li className="text-sm mb-2 ">
              8. <span className="text-black font-semibold">STILL</span>s are meta-NFTs of the whole piece of art piece
              at set moments. Each {b("STILL")} is built by the owners or tenants of the {b("COLOR")}s
            </li>
            <li className="text-sm mb-2 ">
              9. Each {b("COLOR")} has to be kept at a given {b("STILL")}. Owners of a {b("COLOR")} may decide to rent the
              possibility of participation or to still its {b("COLOR")} when the moment comes.
            </li>
            <li className="text-sm mb-2 ">
              10. 10 + 10 + 1 <span className="text-black font-semibold">STILL</span>s will be taken.
            </li>
            <li className="text-sm my-4 ">
              Do you want to participate?
            </li>
            <Link className="text-xs px-3 py-1 mt-2 border border-black hover:shadow rounded-xl bg-white" to={"/"}>
              Get your COLOR
            </Link>
            <li className="text-sm my-4 ">
              Do you want to know more?
            </li>
            <Link className="text-xs px-3 py-1 mt-2 border border-black hover:shadow rounded-xl bg-white" to={"/about"}>
              About
            </Link>

          </ul>
        </div>
      </div>
    </article>
  )
}

HowItWorks.defaultProps = defaultProps;

export default HowItWorks
