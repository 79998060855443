import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import twitter from "../../assets/svgs/twitter.svg";

type IInfoPanelProps = {}

const defaultProps = {}

const InfoPanel: React.FC<IInfoPanelProps> = (props) => {
  const {} = props;

  useEffect(() => {

  }, []);

  return (
    <React.Fragment>
      <div className="w-full p-4">

        <p className="text-sm mb-2 leading-4 "><span className={"text-black font-semibold"}>MARCO</span> is an eternal <span
          className="tracking-wide font-semibold">collaborative</span> and mutant piece of art. It is built as a social and economic interactive experiment that works fully <span
            className="tracking-wide font-semibold">on-chain</span> on the ethereum blockchain.
        </p>
        <p className="text-sm mb-2 leading-4">
          Owning a <span className={"text-black font-semibold"}>COLOR</span> at <span className={"text-black font-semibold"}>MARCO</span> will permit you to participate in a one-of-a-kind art piece built over time by 10000 wallets.
        </p>

        <div className="mb-4 mt-4">
          <Link className="text-xs px-3 py-1 mt-2 border border-black rounded-xl bg-white hover:shadow" to={"/how"}>
            How it works
          </Link>
        </div>

        <p className="text-sm mb-2 leading-4 ">
          Additionally, having a <span className={"text-black font-semibold"}>COLOR</span> will allow you to participate in the <span className={"text-black font-semibold"}>STILL</span>s. Stills will be specific moments in time. When this happens, the whole thing will get frozen, and a meta-NFT will be generated and sold in open auction. The income of these auctions will be distributed to the <span className={"text-black font-semibold"}>COLOR</span> holders.
          <span className={"text-gray-500"}> *Check STILLs section</span>
        </p>

        <div className="mb-4 mt-4">
          <Link className="text-xs px-3 py-1 mt-2 border border-black rounded-xl bg-white hover:shadow" to={"/stills"}>
            STILLS
          </Link>
        </div>

        <p className="text-sm mb-2 leading-4 ">
          <span className={"text-black font-semibold"}>MARCO</span> is as well a private building-up community of creators, builders and dreamers playing with this tech in "other" ways.
          <span className={"text-gray-500"}>  🪄 ☀️ 🕯</span>
        </p>

        <div className="mb-4 mt-4">
          <a target={"_blank"}
             href={process.env.REACT_APP_DISCORD} className="hover:shadow text-xs px-3 py-1 mt-2 border border-black rounded-xl bg-white">
            Get in <span className="text-gray-500">(Discord)</span>
          </a>
          <a className="text-xs px-3 py-1 mt-2 ml-2 border border-black rounded-xl bg-white h-5 w-auto hover:shadow"
             href={"https://twitter.com/metamarco_nft"}
             target={"_blank"}>
          Follow <span className="text-gray-500">
              (twitter)
            </span>
          </a>
        </div>

      </div>
    </React.Fragment>
  )
}

InfoPanel.defaultProps = defaultProps;

export default InfoPanel
