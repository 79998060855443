import React, {useState, useEffect} from 'react';
import ReactGA from "react-ga4";

import {Link} from "react-router-dom";

type IAboutProps = {}

const About: React.FC<IAboutProps> = ({}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

  return (
    <article className="h-auto w-full">
      <div className="md:left-20 top-20 md:w-2/3 lg:w-1/2 w-full md:p-4 pt-28 md:pb-28 px-4 md:pl-20">
        <div className="md:mt-28">
          <p className="mb-4 font-medium text-xl pl-10">· ABOUT</p>
          <ul className="text-sm mb-2 text-gray-900">
            <li className="text-sm mb-2 ">
              Hi there! 👋
            </li>

            <li className="text-sm mb-4">
              I'm a creative mind who lives in the mix among arts, product, and software.
            </li>

            <li className="text-sm mb-2 mt-8 font-semibold">
              WHY
            </li>

            <li className="text-sm mb-2">
              MARCO is far from being just a project. I see it as a visual
              showcase of what blockchain technology is socially capable of.
            </li>

            <li className="text-sm mb-2">
              MARCO means ‘frame’. This frame encourages collaborative interaction between
              creators/participants who can make new stuff.
            </li>

            <li className="text-sm mb-2">
              Blockchain has always been about direct collaboration, it is the killer app. It makes possible
              doing things together without intermediaries, but with rules. Nowadays anyone can create
              those rules, since day one, in a creative, open and global manner and let others interact
              with them.
            </li>

            <li className="text-sm mb-2">
              "There are plenty of rules written in stones. Which stone you choose, that’s up to you"
            </li>

            <li className="text-sm mb-2 ">
              On the other side, I find the NFTs ecosystem is now replicating the ‘old’ way of
              creating and exchanging art, but in a digital environment. There is a huge universe
              of collective creations and it is way more interesting.
            </li>

            <li className="text-sm mb-2 ">
              The barriers to entry of these ecosystems will be near zero. The systems edges will increase their value,
              as the platforms will be autonomous and won’t look for profits but for efficiency.
            </li>

            <li className="text-sm mb-2 ">
              We are living the advent of a brand new era of true interconnections and collaborations between people.
              Artistic, political, social and economic movements will flourish in ways which were not possible or
              allowed before. This technology and its core concepts have been the enablers.
            </li>

            <li className="text-sm mb-2 mt-8 font-semibold">
              THE ART
            </li>

            <li className="text-sm mb-2 ">
              The purpose of the project is to put a spotlight on the possibilities of collective actions, on the
              concept of digital permanency. MARCO also aims to emphasise the value of digital ownership, as well as to show
              how it can give access to subrules of privilege over other actions. One single individual can impact their
              surroundings.
            </li>

            <li className="text-sm mb-2 ">
              From an artistic perspective, we could now have never-ending, forever-changing digital pieces of art. However, those features might be simultaneously
              combined with having the entity of an actual unique physical piece. The
              viewer would always be looking at new piece of art. It is as ephemeral as
              persistent.
            </li>

            <li className="text-sm mb-2">
              The idea of MARCO came from a mix of concepts. On one side, from the building blocks of the Million Dollar Webpage. Its concept resonated with me right at the moment I knew about it, way before the blockchain concept existed. The goals of MARCO are exploring its core concepts, mixing them in a decentralized and collaborative manner, and finally, using them to build something new.
            </li>

            <li className="text-sm mb-2 mt-8 font-semibold">
              THANKS
            </li>

            <li className="text-sm mb-2 ">
              I have to thank quality and creative projects like <a className="underline"
                                                                    href="https://larvalabs.com/cryptopunks/"
                                                                    target={"_blank"}>Cryptopunks</a>, <a
              className="underline" href={"https://www.cryptokitties.co/"} target={"_blank"}>Cryptokitties</a>, <a
              className="underline"
              href={"https://etherscan.io/address/0x438626ba0a4776cf5d27581bdfb03b9633dc0a92#code"} target={"_blank"}>Async
              Art (Avo Labs)</a> <span className="text-xs">NFT Auction contract</span> & <a className="underline"
                                                                                            href={"https://www.lootproject.com/"}
                                                                                            target={"_blank"}>Loot</a> as
              I used as
              reference, not to mention the <a className="underline" href={"https://openzeppelin.com/"}
                                               target={"_blank"}>Open Zeppelin</a> team and
              documentation, <a className="underline" href={"https://hardhat.org/"} target={"_blank"}> Hard-hat
            </a> tools, as
              well to <a className="underline" href={"https://github.com/NoahZinsmeister/web3-react"}
                         target={"_blank"}>web3React</a> library and <a className="underline"
                                                                        href={"https://metamask.io/"}>Metamask</a> documentation
              and wallet. And of course, all people behind making ethereum real.
            </li>
            <li>
              I would like to thank for their support to all the people around who supported me in the building. ❤️
            </li>
            <li className="text-sm mt-4 ">
              Welcome to MARCO
            </li>
            <li className="text-xs mt-4 text-gray-500 flex items-center">
              Contact:
              <a className={"text-xl ml-2"} href={"mailto:marco_nft@protonmail.com"}>
                ✉️
              </a>
            </li>
            <li className="text-sm my-4 ">
              Do you want to participate?
            </li>
            <Link className="text-xs px-3 py-1 mt-2 border border-black hover:shadow rounded-xl bg-white" to={"/"}>
              Get your COLOR
            </Link>
            <div className="mt-8 text-xs text-gray-500 flex h-5 items-center">
              <p>Made with ☕  by</p>
              <a className="text-xs" target={"_blank"} href="https://twitter.com/odrog_design"><span
                className="text-xs ml-2">@odrog_design</span></a>
            </div>
          </ul>
        </div>
      </div>
    </article>
  )
}

About.defaultProps = {}

export default About
