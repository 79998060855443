import React, {useState, useEffect} from 'react';
import {getMaxColors, getPixelPrice, getPixels, IPixel} from "../../features/pixels/pixelsReducers";
import {useSelector} from "react-redux";
import {getColorsContractProvider, getPricesContractProvider} from "../../features/contract/contractReducers"; // we need this to make JSX compile
import {ethers} from 'ethers';
import {blockNumber} from "../Web3Provider/DataLoaders/dataLoaders";
import {dispatch} from "../../store/store";
import {submitPayableTx} from '../../features/transaction/transactionActions';
import {useWeb3React} from "@web3-react/core";
import {getTxStatus} from "../../features/transaction/transactionReducers";
import {loadPricesData} from "../../features/contract/contractActions";
import Countdown from "react-countdown";
import moment from "moment/moment";
import Moment from "react-moment";

type IPixelsSidePanelProps = {}

const defaultProps = {}

const PixelsSidePanel = ({}: IPixelsSidePanelProps) => {
  const price = useSelector(getPixelPrice)
  const colorsContract = useSelector(getColorsContractProvider);
  const txStatus = useSelector(getTxStatus);
  const pricesContract = useSelector(getPricesContractProvider);
  const [prices, setPrices] = useState<any[]>([]);
  const [initBlock, setInitBlock] = useState<number>(0);
  const [durations, setDurations] = useState([]);
  const maxPixels = useSelector(getMaxColors);

  const [actualBlockTime, setActualBlockTime] = useState(0);

  const {account, library} = useWeb3React();

  const [priceSlot, setPriceSlot] = useState(0);
  const [referenceBlock, setReferenceBlock] = useState(0);
  const [remainingSlotTime, setRemainingSlotTime] = useState(0);

  const [isSaleActive, setIsSaleActive] = useState(false);

  const loadPrices = async () => {
    if (!pricesContract) {
      return;
    }

    //UPDATE MAIN PRICE
    try {
      const pricingData = await pricesContract.getAllPricesData();
      setPrices(pricingData[0].map((p: ethers.BigNumberish) => ethers.utils.formatEther(p)));
      setDurations(pricingData[1].map((t: ethers.BigNumberish) => parseInt(t.toString())));
      console.log("PRICES => ", pricingData[0]);
      console.log("DURATIONS => ", pricingData[1]);

      const priceSlot = await pricesContract.slot();
      setPriceSlot(parseInt(priceSlot.toString()));
      const referenceBlock = await pricesContract.priceBaseBlockT();
      setReferenceBlock(parseInt(referenceBlock.toString()));
      console.log("SLOT => ", parseInt(priceSlot.toString()));
      console.log("REFERENCE BLOCK =>", parseInt(referenceBlock.toString()));

      // const initBlock = await pricesContract.projectInitBlockT();
      // console.log("SC_CREATION_BLOCK => ", initBlock.toString());
      // setInitBlock(parseInt(initBlock.toString()));
      dispatch(loadPricesData);

      const isActive = await colorsContract.isSaleActive();
      console.log("IS_SALE_ACTIVE => ", isActive);
      setIsSaleActive(isActive);

    } catch (e) {
      console.error(e);
    } finally {
      console.log('We do cleanup here');
    }

  }

  const updateBlockTime = async () => {
    if (library && prices.length > 0 && referenceBlock) {
      const actualBlockTime = await library.getBlock('latest');
      console.log('ACTUAL BLOCK TIME => ', actualBlockTime.timestamp);
      setActualBlockTime(actualBlockTime.timestamp);
      console.log(referenceBlock, durations[priceSlot], actualBlockTime.timestamp)
      console.log(referenceBlock + durations[priceSlot] - actualBlockTime.timestamp)
      setRemainingSlotTime(referenceBlock + durations[priceSlot] - actualBlockTime.timestamp);
    }
  }

  useEffect(() => {
    updateBlockTime();
    const interval = setInterval(() => {
    }, 2000)
    return () => {
      clearInterval(interval);
    };
  }, [account, prices, referenceBlock]);

  const reducePixelPrice = () => {
    if (remainingSlotTime > 0) {
      return;
    }
    dispatch(submitPayableTx(
      {
        functionName: "reducePrice",
        valueArgs: [],
        overrides: {},
        name: "Reduce Price",
        contract: pricesContract
      }
    ))
  }


  useEffect(() => {
    if (pricesContract) {
      dispatch(loadPricesData());
    }
    return () => {
    };
  }, [txStatus, pricesContract]);


  useEffect(() => {
    loadPrices();

    const projectBlock = (actualBlockTime - initBlock);
    console.log("PROJECT BLOCK => ", projectBlock);

  }, [colorsContract, pricesContract, price, txStatus, actualBlockTime]);


  const convertTime = (value: number) => {
    return <Moment
      duration={new Date()}
      date={Date.now() + value * 1000}/>
  }

  return (
    <section className="w-full p-4 flex-col flex justify-between">


      <div className="mb-5">
        {account && <>
            <p className="text-sm mb-2 text-gray-500">COLOR price:</p>
            <p className="text-sm mb-4"><span className={"text-xl text-black"}> {price} Ξ </span></p>
            <p className="text-sm mb-2 text-gray-500">Why:</p>
            <p className="text-xs mb-2 ">Price is based as a entry barrier and a participation structure, all funds
                will be used to fund time invested into the subsequent projects branched from MARCO.</p>
        </>
        }
        <p className="text-xs mb-2 ">COLOR ownership is limited to one per wallet to promote MARCO as a frame of
          interaction among different people. Being the door to other interactivity projects built upon, in, and
          over it. Some of them capitalising and speculating from an artistic point of view, others won't.</p>
      </div>

      {account && <>
          <div className="mb-5">
              <p className="text-xs mb-2 text-gray-400">If price is too expensive for you and you want to participate of
                  it.
                  Write (with time) explaining why.</p>
          </div>
      </>}
    </section>
  )
}

PixelsSidePanel.defaultProps = defaultProps;

export default PixelsSidePanel
