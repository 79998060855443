import React from "react";
import ReactDOM from "react-dom";
import './index.scss';
import store from './store/store';

// We import bootstrap here, but you can remove if you want
// import "bootstrap/dist/css/bootstrap.css";
import RootContainer from "./components/RootContainer";
import {Provider} from "react-redux";

// This is the entry point of your application, but it just renders the Dapp
// react component. All of the logic is contained in it.

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store()}>
            <RootContainer/>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
