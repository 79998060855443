import React, {useState, useEffect} from 'react';
import styles from "./navbar.module.scss"
import {Link, useLocation} from "react-router-dom";
import {getAddress} from "../../features/account/accountReducers";
import {useDispatch, useSelector} from 'react-redux';
import {Account, blockNumber} from "../Web3Provider/DataLoaders/dataLoaders";
import {useWeb3React} from "@web3-react/core";
import {setEthersProvider} from "../../features/contract/contractActions";
import AccountService from "../../services/accountService";
import cx from "classnames"

import logo from "../../assets/svgs/logo.svg"
import logoFlash from "../../assets/svgs/logo_flash.svg"
import logoColor from "../../assets/svgs/logoColors.svg"
import loading from "../../assets/svgs/loading.svg"
import {useEagerConnect} from '../Web3Provider/hooks';
import {MetamaskService} from "../../services/metamaskService";
import {
  getColorsContractProvider,
  getIsColorsLoading,
  getPricesContractProvider
} from "../../features/contract/contractReducers";
import {getTxStatus} from "../../features/transaction/transactionReducers";
import {BigNumber} from "ethers";

type INavBarProps = {}

const NavBar: React.FC<INavBarProps> = ({}) => {

  const address = useSelector(getAddress);
  const txStatus = useSelector(getTxStatus);

  const dispatch = useDispatch()
  const [isMetamask, setIsMetamask] = useState(true);
  const aBlock = blockNumber();
  const {account, library} = useWeb3React();
  const pricesContract = useSelector(getPricesContractProvider);
  const colorsContract = useSelector(getColorsContractProvider);

  const isLoading = useSelector(getIsColorsLoading);
  const [path, setPath] = useState<string>("");
  const [isOwning, setIsOwning] = useState<number>(0);

  const loadLibrary = async () => {
    if (!window.ethereum) {
      setIsMetamask(false);
      return;
    } else {
      window.ethereum.enable()
        .then(() => {
          if (library) {
            dispatch(setEthersProvider(library));
            return AccountService._initializeEthersContract(library);
          }
        }).then(() => {

      }).catch((err: any) => {
        alert('Check your wallet')
      });
    }
  }

  const loadInitBlock = async () => {
    const initBlock = pricesContract
  }

  const loadAccountData = async () => {
    if (!colorsContract) {
      return;
    }

    colorsContract.balanceOf(account)
      .then((ownerBalance: BigNumber) => {
        const isOwning = parseInt(ownerBalance?.toString());
        console.log('balance', isOwning);
        setIsOwning(isOwning);
      }).catch((err: any) => {
      console.log(err);
    });
  }

  let location = useLocation();

  React.useEffect(() => {
    setPath(location.pathname);
    console.log(location.pathname);
  }, [location]);

  React.useEffect(() => {
    if (window.ethereum) {
      window.ethereum.enable()
    }
    loadAccountData()
  }, [colorsContract, txStatus]);

  return (
    <React.Fragment>
      {!isMetamask && <div className={"w-full h-full z-50 flex justify-center items-center"}>
          <div onClick={() => setIsMetamask(true)} className="w-full h-full bg-black opacity-40 absolute ">
          </div>

          <div className="bg-white p-6 absolute m-10 border-black">
              <p className={"text-sm pb-6"}>In order to interact with the art piece you need a browser capable of
                  connecting to the ethereum blockchain.
              </p>
              <p className={"text-xs text-center border-2 border-black hover:shadow cursor-pointer px-4 py-2"}>Let's
                  start
                  with <a href="https://metamask.io/">metamask</a></p>
          </div>
      </div>}
      <nav className={styles.navbar}>
        {path !== "/" && <div className={cx("absolute z-40 flex", styles.home)}>
            <Link to={"/"} className={cx("h-4 w-4 border-black border")}/>
            <p className={"text-xs text-gray-500 ml-2"}>Back</p>
        </div>}

        <div className={cx("absolute z-40 flex hidden md:flex w-1/2 md:text-left justify-center", styles.bar)}>
          <Link className="text-xs px-3 py-1 m-2 w-28 text-center border border-black hover:shadow rounded-xl bg-white"
                to={"/how"}>How it works</Link>
          <Link className="text-xs px-3 py-1 m-2 w-28 text-center border border-black hover:shadow rounded-xl bg-white"
                to={"/stills"}>STILLs</Link>
          <Link className="text-xs px-3 py-1 m-2 w-28 text-center border border-black hover:shadow rounded-xl bg-white"
                to={"/about"}>About</Link>
        </div>

        <div className={styles.left}>
          {/* LINE */}
          <div className={styles.line}/>

          <Link to={"/"}>
            {path?.includes('flashes') ?
              <img src={logoFlash} className="absolute top-12 left-3 pl-1 h-8'"/> :
              path?.includes('color') ?
                <img src={logoColor} className="absolute top-12 left-3 pl-1 h-8"/> :
                path?.includes('how') ?
                  <p className="absolute top-12 left-3 pl-1 h-8 text-4xl  font-sofia">How</p> :
                  path?.includes('about') ?
                    <p className="absolute top-12 left-3 pl-1 h-8 text-4xl  font-sofia">About</p> :
                    path?.includes('stills') ?
                      <p className="absolute top-12 left-3 pl-1 h-8 text-4xl  font-sofia">Stills</p> :
                      <img src={logo} className="absolute top-12 left-3 pl-1 h-8"/>
            }
          </Link>

        </div>

        <div className={styles.accountElement}>

          {!!account ?
            <Account/> :
            <button
              className={cx(
                "px-6 py-1 text-sm", styles.connectBtn
              )}
              onClick={() => {
                loadLibrary();
                MetamaskService.existProvider();
                // useEagerConnect()
              }}>
              Connect
            </button>
          }

          <div className={styles.userInfo}>
            {!!account ? <>
                {isLoading && <div className={"flex items-center"}>
                    <img src={loading} className="h-6"/>
                    <p className={"text-xs text-gray-500 ml-1"}>Loading blockchain data...</p>
                </div>}
                {isOwning > 0 && !path?.includes('account') && <Link className={"text-sm text-gray-500"} to={"/account"}>
                    My account &gt;
                </Link>}
              </>
              :
              <p className={"text-xs text-gray-500"}>
                Connect your <a className="underline" href="https://metamask.io/" target="_blank">metamask</a>*
              </p>
            }
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}

NavBar.defaultProps = {}

export default NavBar
