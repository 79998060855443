import {IPixel} from "./pixelsReducers";

export enum PixelsTypes {
  SET_PIXELS = 'Pixels/set/all',
  UPDATE_PIXEL = 'Pixels/update/pixel',
  UPDATE_PIXELS = 'Pixels/update/pixels',
  SET_MAX_PIXELS = 'Pixels/set/max',
  SET_PRICE = 'Pixels/set/price',
}

export type IPixelsActions =
  ISetPixelsProvider |
  IUpdatePixel |
  IUpdatePixels |
  ISetPixelPrice |
  ISetMaxPixels
  ;

export interface ISetPixelsProvider {
  type: PixelsTypes.SET_PIXELS,
  pixels: any[]
}
export const setPixels = (pixels: any): ISetPixelsProvider => {
  console.log("SET_PIXELS ACTION", pixels);
  return {
    type: PixelsTypes.SET_PIXELS,
    pixels
  }
}

export interface IUpdatePixel {
  type: PixelsTypes.UPDATE_PIXEL,
  pixel: IPixel
}
export const updatePixel = (pixel: IPixel): IUpdatePixel => {
  console.log("UPDATE_PIXEL ACTION", pixel);
  return {
    type: PixelsTypes.UPDATE_PIXEL,
    pixel
  }
}

export interface IUpdatePixels {
  type: PixelsTypes.UPDATE_PIXELS,
  pixels: IPixel[]
}
export const updatePixels = (pixels: IPixel[]): IUpdatePixels => {
  console.log("UPDATE_PIXELs ACTION", pixels);
  return {
    type: PixelsTypes.UPDATE_PIXELS,
    pixels
  }
}

export interface ISetMaxPixels {
  type: PixelsTypes.SET_MAX_PIXELS,
  max_pixels: number
}
export const setMaxPixels = (max_pixels: number): ISetMaxPixels => {
  console.log("SET_MAX_PIXELS ACTION", max_pixels);
  return {
    type: PixelsTypes.SET_MAX_PIXELS,
    max_pixels
  }
}

export interface ISetPixelPrice {
  type: PixelsTypes.SET_PRICE,
  price: number | string | null
}
export const setPixelPrice = (price: number | string | null): ISetPixelPrice => {
  console.log("SET_PRICE ACTION", price);
  return {
    type: PixelsTypes.SET_PRICE,
    price
  }
}
