import React, {useState, useEffect, useRef} from 'react';

type ICanvasProps = {
  r?: number,
  g?: number,
  b?: number,
  x?: number,
  y?: number,
}

const isBrowser = () => typeof window !== "undefined"

const Canvas: React.FC<ICanvasProps> = (props: any) => {
  const {r,g,b,x,y} = props;
  const canvasRef = useRef(null)
  const metaRef = useRef(null)

  const buildFavIcon = () => {
    const canvas = canvasRef.current
    // @ts-ignore
    const ctx = canvas.getContext('2d');
    //Our first draw
    // @ts-ignore
    canvas.width = 100;
    // @ts-ignore
    canvas.height = 100;
    console.log(canvas, r, g, b);
    var img = new Image();
    // console.log(ctx);
    // ctx.drawImage(img, 0, 0);
    ctx.fillStyle = `rgb(${r?r:0},${g?g:0},${b?b:0})`;
    // ctx.ellipse(50, 50, 50, 50, Math.PI / 4, 0, 2 * Math.PI);
    ctx.fillRect(0, 0, 100, 100);

    ctx.fillStyle = `rgb(${b},${r},${g})`;
    ctx.fillRect(x, y, 20, 20);
    ctx.fill();
    // ctx.fillRect(0, 0, 100, 100);
    // ctx.fillStyle = '#FFFFFF';
    // ctx.font = 'bold 60px Fredoka One';
    // ctx.fillText('G', 29, 72);
    img.src = '/favicon.ico';
    img.onload = function () {
      var link = document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'icon';
      // @ts-ignore
      link.href = canvas.toDataURL("image/x-icon", 0.5);
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(link)
    }
  }


  useEffect(() => {
    buildFavIcon()
  }, [r, g, b])

  return (
    <canvas className="fixed top-0 right-0 z-50 hidden" ref={canvasRef} {...props}/>
  )
}

Canvas.defaultProps = {}

export default Canvas
