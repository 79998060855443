import {ofType, Epic} from 'redux-observable'
import {catchError, switchMap, map, mergeMap} from 'rxjs/operators'
import {RootState} from "../../store/indexReducers";
import accountService from "../../services/accountService";
import {from, of} from "rxjs";
import {setTxFailed, setTxSuccess, setTxTentative, TransactionTypes} from '../transaction/transactionActions';
import {ContractTypes, isColorsDataLoading} from './contractActions';
import {IPixel} from "../pixels/pixelsReducers";
import {setMaxPixels, setPixelPrice, setPixels} from "../pixels/pixelsActions";
import {ethers} from "ethers";

export const loadColorsData_Epic: Epic<any, any, RootState, any> = (
  action$,
  state$: any
) => {
  return action$.pipe(
    ofType(ContractTypes.LOAD_COLORS_DATA),
    switchMap((action: any): any => {
      const colorsContract = state$.value.contractReducer.colorsContract;
      const allPixels = state$.value.pixelsReducer.pixels;
      return colorsContract && from(
        Promise.all([
          // colorsContract.getAllPixelsData(),
          // colorsContract.getAllColors(),
          // colorsContract.getAllPositions(),
          colorsContract.getAllColors_R(),
          colorsContract.getAllColors_G(),
          colorsContract.getAllColors_B(),
          colorsContract.getAllPositions_X(),
          colorsContract.getAllPositions_Y(),
          colorsContract.MAX_COLORS(),
        ])
      ).pipe(
        mergeMap((response: any): any => {
            const [colorsR, colorsG, colorsB, posX, posY] = response;

            console.log("EPIC: LOAD_COLORS_DATA REMOTE COLORS", response);

            const newPixels = {...allPixels}

            let pixels: IPixel[] = [];
            posX.forEach((p: any, i: any) => {
              const key = posX[i].toString() + "_" + posY[i].toString();
              // let tokenId = p.tokenId.toString();

              newPixels[key].tokenId = i;
              newPixels[key].positionX = posX[i];
              newPixels[key].positionY = posY[i];
              newPixels[key].colorR = colorsR[i];
              newPixels[key].colorG = colorsG[i];
              newPixels[key].colorB = colorsB[i];
              newPixels[key].isMinted = true;
            })

            console.log('NEWPIXELS', newPixels);

            // MAX PIXELS;
            const max_pixels = response[2];
            let mxpxls = max_pixels.toString();

            return of(
              setPixels(newPixels),
              setMaxPixels(mxpxls),
              isColorsDataLoading(false)
            )

          }
        ),
        catchError((err): any => {
            console.log("EPIC: LOAD_COLORS_DATA ERR => ", err);
            return of(setPixels(allPixels))
          }
        )
      )
    })
  )
}
export const loadPricesData_Epic: Epic<any, any, RootState, any> = (
  action$,
  state$: any
) => {
  return action$.pipe(
    ofType(ContractTypes.LOAD_PRICES_DATA),
    switchMap((action: any): any => {
      const pricesContract = state$.value.contractReducer.pricesContract;
      return pricesContract && from(
          pricesContract.COLORPrice()
      ).pipe(
        mergeMap((priceResponse: any): any => {
            console.log("COLOR_PRICE =>", priceResponse)
            const price = ethers.utils.formatEther(priceResponse);
            console.log("COLOR_PRICE =>", price)
            return of(
              setPixelPrice(parseFloat(price))
            )
          }
        ),
        catchError((err): any => {
            return of(
              setPixelPrice(0)
            )
          }
        )
      )
    })
  )
}
