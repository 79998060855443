import React, {useState, useEffect} from 'react';

type ICOLORBuilderProps = {
  pixel: any
}

const defaultProps = {}

const COLORBuilder: React.FC<ICOLORBuilderProps> = (props) => {
  const {pixel} = props;

  const buildSVGPixel = () => {

    const line1 = document.getElementById("line1");
    const coordsText = document.getElementById("coordsText");
    const line2 = document.getElementById("line2");
    line1 && line1.setAttribute("style", "stroke:rgb(0,0,0);stroke-width:0.2");
    line2 && line2.setAttribute("style", "stroke:rgb(0,0,0);stroke-width:0.2");

    const coordsId = pixel.positionX.toString() + "_" + pixel.positionY.toString();

    /*
     IF PIXEL IS NOT MINTED YET
     */

    if (pixel &&
      pixel.colorR === undefined &&
      pixel.colorG === undefined &&
      pixel.colorB === undefined
    ) {
      return (

        <svg
          xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          viewBox="0 0 100 100"
        >
          <rect
            x={pixel.positionX}
            y={pixel.positionY}
            stroke={"rgb(" + 0 + "," + 0 + "," + 0 + ")"}
            strokeWidth="0.2"
            width="0.8" height="0.8"
            fill={"rgb(" + 0 + "," + 0 + "," + 0 + ")"}
          />
        </svg>
      );


    } else if (pixel.colorR !== undefined && pixel.colorG !== undefined && pixel.colorB !== undefined) {

      /*
      PIXEL COLOR EXISTS
       */

      let color = "rgb(" + pixel.colorR + "," + pixel.colorG + "," + pixel.colorB + ")";
      let sc_R = pixel.colorG,
        sc_G = pixel.colorB,
        sc_B = pixel.colorR;

      let pc_R = pixel.colorR,
        pc_G = pixel.colorG,
        pc_B = pixel.colorB;

      if (
        pc_R < 127 &&
        pc_G < 127 &&
        pc_B < 127
      ) {
        pc_R = (255 - pc_R) / 3
        pc_G = (255 - pc_G) / 3
        pc_B = (255 - pc_B) / 3
      }

      if (
        pc_R >= 127 &&
        pc_G >= 127 &&
        pc_B >= 127
      ) {
        pc_R = pc_R / 3
        pc_G = pc_G / 3
        pc_B = pc_B / 3
      } else {
        pc_R = pc_R / 1.5
        pc_G = pc_G / 1.5
        pc_B = pc_B / 1.5
      }

      // TOO DARK, MAKE IT LIGHTER
      if (
        sc_R < 127 &&
        sc_G < 127 &&
        sc_B < 127
      ) {
        sc_R = (255 - sc_R) / 3
        sc_G = (255 - sc_G) / 3
        sc_B = (255 - sc_B) / 3
      }

      // TOO LIGHT, MAKE IT DARKER
      if (
        sc_R >= 127 &&
        sc_G >= 127 &&
        sc_B >= 127
      ) {
        sc_R = sc_R / 3
        sc_G = sc_G / 3
        sc_B = sc_B / 3
      }

      let colorSC = "rgb(" + sc_R + "," + sc_G + "," + sc_B + ")";
      let colorMainSecondary = "rgb(" + pc_R + "," + pc_G + "," + pc_B + ")";

      coordsText && coordsText.setAttribute(
        "style",
        "fill:" + colorMainSecondary
      );

      return (

        <svg
          xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          viewBox="0 0 100 100"
        >
          <rect
            x="0" y="0"
            width="100" height="100"
            fill={color}/>

          <rect
            x={pixel.positionX}
            y={pixel.positionY}
            stroke={colorSC}
            strokeWidth="0.2"
            width="0.8" height="0.8"
            fill={colorSC}
          />

          {/*          <text transform="matrix(0.3 0 0 0.3 4 7)"
                fill={colorMainSecondary}
          >
            Love
          </text>
          <text transform="matrix(0.3 0 0 0.3 4 17)"
                fill={colorMainSecondary}
          >
            Love
          </text>
          <text transform="matrix(0.3 0 0 0.3 4 27)"
                fill={colorMainSecondary}
          >
            Love
          </text>
          <text transform="matrix(0.3 0 0 0.3 4 97)"
                fill={colorMainSecondary}
          >
            Love
          </text>*/}

        </svg>

      )
    }
  }

  useEffect(() => {

  }, [pixel]);

  return (
    <React.Fragment>
      {buildSVGPixel()}
    </React.Fragment>
  )
}

COLORBuilder.defaultProps = defaultProps;

export default COLORBuilder
