import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {getColorsContractProvider} from "../../features/contract/contractReducers";
import styles from "./TokenRenderer.module.scss"
import {useParams} from "react-router-dom";

export type TokenrendererProps = {}

export default function Tokenrenderer({}: TokenrendererProps) {
  const contract = useSelector(getColorsContractProvider);
  const [tokenURI, setTokenURI] = useState("");
  const params: any = useParams();

  const loadData = async () => {
    console.log("tokenURI hola");
    if (!contract) {
      return;
    }
    const tokenURI = await contract.tokenURI(parseInt(params.id));
    if (tokenURI) {
      console.log("tokenURI", tokenURI.toString());
      setTokenURI(tokenURI.toString());
    }
  }

  useEffect(() => {
    loadData()
    return () => {

    };
  }, [contract]);


  return (
    <section>
      TOKEN URI
      <div className={styles.tokenHolder} dangerouslySetInnerHTML={{__html: tokenURI}}/>
    </section>
  );
}
