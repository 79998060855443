import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import styles from "./pixelpage.module.scss"
import {useSelector} from "react-redux";
import {getColorsContractProvider, getRentContractProvider} from "../../features/contract/contractReducers";
import {getPixelPrice, getPixels, IPixel} from "../../features/pixels/pixelsReducers";
import {ChromePicker} from 'react-color';
import {BigNumber, ethers} from "ethers";
import {dispatch} from "../../store/store";
import ColorSetter from "../ColorSet/ColorSet";
import ColorChange from "../ColorChanger/ColorChange";
import {cleanTxState, submitPayableTx} from "../../features/transaction/transactionActions";
import cx from "classnames";
import {useWeb3React} from "@web3-react/core";
import RentSettings from "../RentSettings/RentSettings";
import COLORBuilder from "../ColorSquareBuilder/COLOR_Builder";
import ColorRent from "../ColorRent/ColorRent";
import {blockNumber} from "../Web3Provider/DataLoaders/dataLoaders";
import {getTxStatus} from "../../features/transaction/transactionReducers";
import Countdown from 'react-countdown';
import Moment from 'react-moment';
import {BuildHelmet} from '../HelmetBuilder';
import Canvas from "../Canvas";
import ReactGA from "react-ga4";


type IPixelPageProps = {}

const PixelPage: React.FC<IPixelPageProps> = ({}) => {
  const params: any = useParams();
  const coordsId = params.id;
  const coords = coordsId.split('_');
  const colorsContract = useSelector(getColorsContractProvider);
  const rentContract = useSelector(getRentContractProvider);
  const txStatus = useSelector(getTxStatus);
  const allPixels = useSelector(getPixels);

  const pixelPrice = useSelector(getPixelPrice);

  // COLOR CHANGE AND MINT
  const [viewColorSetter, setViewColorSetter] = useState(false);
  const [viewColorChanger, setViewColorChanger] = useState(false);
  const [pixel, setPixel] = useState<IPixel>({
    positionX: parseInt(coords[0]),
    positionY: parseInt(coords[1]),
  });
  const [color, setColor] = useState<any>();
  const [selectedColor, setSelectedColor] = useState<any>();


  // RENTING
  const [viewColorRent, setViewColorRent] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<any>(null);
  const [isRentingVisible, setIsRentingVisible] = useState(false);
  const [rentingData, setRentingData] = useState<{
    isRenting: boolean,
    prices: [],
    times: []
  }>();

  // RENTER
  const [lesseeData, setLesseeData] = useState<{
    lessee: string;
    endBlockTime: any
  }>();


  // SALE ACTIVE
  const [isSaleActive, setIsSaleActive] = useState(false);

  const {account, library} = useWeb3React();

  const [blockTime, setBlockTime] = useState<any>(0);

  const loadData = async () => {
    console.log("PARAMS => ", coords);
    let pixelData;
    // console.log("ALL PIXELS PIXEL PAGE => ", allPixels);    // Check if ethers provider is connected
    if (!colorsContract) {
      // alert("Connect Metamask");
      return;
    }

    // Check if the pixel exist already
    let selectedPixel: IPixel = {
      positionX: coords[0],
      positionY: coords[1]
    };

    console.log("SELECTED LOCAL PIXEL => ", selectedPixel);

    // GET TOKEN ID FROM POSITION
    const tokenIdPosition = await colorsContract.getTokenIdByPosition(
      selectedPixel.positionX,
      selectedPixel.positionY
    );

    console.log("REMOTE TOKENID => ", tokenIdPosition)

    if (tokenIdPosition.length <= 2) {
      return;
    }

    let decodedToken = ethers.utils.defaultAbiCoder.decode(["uint256"], tokenIdPosition).toString();
    console.log("DECODED TOKENID => ", decodedToken)

    // GET COLOR DETAIL DATA
    pixelData = await colorsContract.getCOLORData(decodedToken);
    console.log("LOAD BLOCKCHAIN PIXEL", pixelData);

    if (pixelData) {
      selectedPixel.tokenId = parseInt(decodedToken);
      selectedPixel.colorR = pixelData[0];
      selectedPixel.colorG = pixelData[1];
      selectedPixel.colorB = pixelData[2];
      selectedPixel.positionX = pixelData[3];
      selectedPixel.positionY = pixelData[4];
      selectedPixel.owner = pixelData[5];
    }

    console.log("SELECTED BLOCKCHAIN PIXEL", selectedPixel);
    setPixel(selectedPixel);

    const rentInfo = await rentContract.getColorRentingData(parseInt(decodedToken));
    console.log("RENTING DATA", rentInfo);
    const rentingData = rentInfo[0];
    const rentedData = rentInfo[1];

    const endBlockTime = rentedData['endLeaseTimeStamp'];
    setLesseeData({
      lessee: rentedData['lessee'],
      endBlockTime: endBlockTime && endBlockTime.toString(),
    })

    const isRenting = rentingData['isOnRent'];
    const prices = rentingData['prices'].map((price: ethers.BigNumberish) => {
      return ethers.utils.formatEther(price);
    });
    const times = rentingData['durations'];

    setRentingData({
      isRenting: isRenting,
      times: times.map((t: ethers.BigNumber) => parseFloat(t.toString()) / 60 / 60 / 24),
      prices: prices.map((p: any) => parseFloat(p.toString())),
    })

    // BLOCK TIME
    if (library) {
      const blockTime = await library.getBlock('latest');
      setBlockTime(blockTime.timestamp);
    }

    const isActive = await colorsContract.isSaleActive();
    console.log("IS_SALE_ACTIVE => ", isActive);
    setIsSaleActive(isActive);
  }


  const loadRenting = async () => {

  }

  const [remainingLeassingTime, setRemainingLeassingTime] = useState<any>(null);
  const [testTime, setTestTime] = useState<any>(null);
  const blockN = blockNumber()

  useEffect(() => {
    const interval = setInterval(() => {
      if (blockTime && lesseeData) {
        setRemainingLeassingTime(<Moment
          format={"D[d] H[h] m[m] s[s]"}
          duration={new Date()}
          date={(new Date((lesseeData?.endBlockTime) * 1000))}
        />);
        setTestTime(new Date((lesseeData?.endBlockTime - blockTime)))
      }
    }, 1000);
    return () => clearInterval(interval);

  }, [blockN]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });    return () => {

    };
  }, []);

  useEffect(() => {
    loadData()
    loadRenting()
  }, [
    colorsContract,
    txStatus
  ]);

  const changeColor = (color: any, event: any) => {
    setSelectedColor(color)
  }

  const changeColorSetterVisibility = () => {
    setViewColorSetter(!viewColorSetter)
    setViewColorChanger(false)
    setViewColorRent(false)
    setIsRentingVisible(false);
  }
  const changeColorChangerVisibility = () => {
    setViewColorChanger(!viewColorChanger)
    setViewColorSetter(false)
    setViewColorRent(false)
    setIsRentingVisible(false);
  }
  const changeColorRentVisibility = () => {
    setViewColorRent(!viewColorRent)
    setViewColorChanger(false)
    setViewColorSetter(false)
    setIsRentingVisible(false);
  }
  const changeRentSettingsVisibility = () => {
    setIsRentingVisible(!isRentingVisible);
    setViewColorChanger(false)
    setViewColorSetter(false)
    setViewColorRent(false)
  }

  /*

  CALL CONTRACT

   */
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return () => {
    };
  }, []);


  const mintPixel_new = () => {
    if (!selectedColor) {
      return;
    }
    dispatch(cleanTxState());
    dispatch(submitPayableTx(
      {
        functionName: "mintCOLOR",
        valueArgs: [
          selectedColor?.rgb?.r,
          selectedColor?.rgb?.g,
          selectedColor?.rgb?.b,
          pixel.positionX,
          pixel.positionY
        ],
        overrides: {
          // the value could be a number, or a
          value: ethers.utils.parseEther(pixelPrice.toString())
        },
        name: "Mint COLOR X" + pixel.positionX + ":" + pixel.positionY + "Y"
      }
    ));
    changeColorSetterVisibility();
  }


  const changeRemoteColor_new = async () => {
    console.log('changeColor');
    if (!pixel) {
      return;
    }
    dispatch(submitPayableTx(
      {
        functionName: "changeColor",
        valueArgs: [
          pixel.tokenId,
          selectedColor?.rgb?.r,
          selectedColor?.rgb?.g,
          selectedColor?.rgb?.b,
        ],
        overrides: {
          // the value could be a number, or a
          // value: ethers.utils.parseEther(pixelPrice.toString())
        },
        name: "Change color"
      }
    ));
    changeColorChangerVisibility();
  }

  const rentColor = async () => {
    if (!pixel && !rentingData) {
      return;
    }
    if (!rentingData || rentingData.prices.length === 0) {
      return;
    }
    if (!selectedSlot) {
      return;
    }
    let price: number = rentingData.prices[selectedSlot];
    price = price + price / 20;
    console.log(price, pixel.tokenId)
    dispatch(submitPayableTx(
      {
        functionName: "rentCOLOR",
        valueArgs: [
          pixel.tokenId,
          selectedSlot,
        ],
        overrides: {
          // the value could be a number, or a
          value: ethers.utils.parseEther(price.toString())
        },
        name: "Rent COLOR",
        contract: rentContract
      }
    ));
    // changeColorRentVisibility();
  }

  const rentSettings = (rentingData: {
    isRenting: boolean;
    prices: number[];
    durations: number[];
  }) => {
    console.log(rentingData)
    if (!pixel) {
      return;
    }
    const parsedPrices = rentingData.prices.map(price => {
      return ethers.utils.parseEther(price.toString())
    })
    const seconds = rentingData.durations.map(days => {

      return days * 24 * 60 * 60;
    })
    dispatch(submitPayableTx(
      {
        functionName: "setColorRenting",
        valueArgs: [
          pixel.tokenId,
          rentingData.isRenting,
          parsedPrices,
          seconds
        ],
        overrides: {},
        name: "Set rent settings",
        contract: rentContract,
      }
    ));
    changeRentSettingsVisibility()
  }

  const buildMoment = () => {
    setInterval(() => {
      return <Moment
        duration={new Date()}
        date={(new Date((lesseeData?.endBlockTime - blockTime) * 1000))}
      />
    }, 1000);
  }


  return (
    <>
      <BuildHelmet
        title={"COLOR | " + pixel.positionX + ":" + pixel.positionY + " |"}
        description={"COLOR " + pixel.positionX + ":" + pixel.positionY + " is one the MARCO's NFTs that compose a collaborative art piece built on-chain at the ethereum blockchain"}
        // facebookImg={""}
        // linkedInImg={""}
        // twitterImg={""}
      />
      <Canvas r={pixel.colorR} g={pixel.colorG} b={pixel.colorB} x={pixel.positionX} y={pixel.positionY}/>

      <article className={styles.pixelMainContainer}>


        {/*

      CENTER SPACE

      */}
        <section className={"pt-28 md:pt-0"}>
          <div>
            <div className="text-center">
              <p className="text-sm text-gray-500">COLOR</p>
              <p className="text-3xl">
                <span className="text-gray-400">X</span>
                {pixel.positionX}:{pixel.positionY}
                <span className="text-gray-400">Y</span>
              </p>
            </div>
            <div className={styles.pixelContainer}>
              {viewColorSetter && <ColorSetter
                  close={changeColorSetterVisibility}
                  action={mintPixel_new}
                  color={selectedColor}
                  updateColor={changeColor}
              />}
              {viewColorChanger && <ColorChange
                  close={changeColorChangerVisibility}
                  action={changeRemoteColor_new}
                  color={selectedColor}
                  updateColor={changeColor}
              />}
              {/*            {viewColorRent && <ColorRent
                close={changeColorRentVisibility}
                action={rentColor}
                color={selectedColor}
                selectedSlot={selectedSlot}
                changeSelectedSlot={(value: number) => setSelectedSlot(value)}
                updateColor={changeColor}
                pricesData={rentingData?.prices}
                durationData={rentingData?.times}
            />}*/}
              {isRentingVisible && <div className={"absolute w-full h-full left-0 top-0 z-30 bg-white"}>
                  <RentSettings
                      isRenting={rentingData?.isRenting}
                      pricesData={rentingData?.prices}
                      durationData={rentingData?.times}
                      action={rentSettings}
                      close={changeRentSettingsVisibility}
                  />
              </div>}
              {rentingData?.isRenting && lesseeData?.endBlockTime < blockTime &&
                  <p className={"px-4 py-1 text-xs bg-white rounded-xl border border-black absolute top-2 left-2 z-20"}>ON
                      RENT</p>
              }
              <COLORBuilder pixel={pixel}/>
            </div>
          </div>

        </section>


        {/*
      LEFT BAR
      */}

        <section className="md:absolute md:w-1/4 md:left-0 md:top-20 w-full ">


          <div className="w-full p-6">
            {/*MOBILE BUTTON*/}
            {
              !pixel.owner &&
                <div className="relative w-full md:hidden">
                    <button
                        className={cx("w-full md:w-auto px-10 py-2 border-2 border-black ", !isSaleActive ? "opacity-30" : "hover:shadow cursor-pointer")}
                        onClick={() => {
                          ReactGA.event({
                            category: "COLOR",
                            action: "MOBILE CLAIM COLOR SELECTION",
                            label: pixel.positionX + ":" + pixel.positionY, // optional
                          });
                          isSaleActive && setViewColorSetter(!viewColorSetter);
                        }}
                    >
                        Claim this COLOR
                    </button>
                </div>
            }


            {/* <div className="mb-8 mt-4 h-1 bg-gray-200 w-1/2 rounded-sm"></div> */}
            {/*<p className="text-sm mb-2 leading-4 text-gray-700">Selected PIXEL:</p>*/}
            <div className="mb-8 mt-5 h-1 bg-gray-200 w-full md:w-1/2 rounded-sm"/>
            <p className="text-sm mb-2 leading-4 text-gray-700">POSITION:</p>
            <p className="text-3xl mb-8">
              <span className="text-gray-400">X</span>
              {pixel.positionX}:{pixel.positionY}
              <span className="text-gray-400">Y</span>
            </p>
            <div className="mb-8 mt-5 h-1 bg-gray-200 w-full md:w-1/2 rounded-sm"/>


            {/*
          COLOR INFO
          */}
            <p className="text-sm mb-2 leading-4 text-gray-700">COLOR:</p>
            {pixel.colorR && pixel.colorG && pixel.colorB ? (
              <p className="text-3xl flex mb-8">
                <span className="text-gray-400">RGB:</span>{pixel.colorR}, {pixel.colorG}, {pixel.colorB}
              </p>
            ) : <div className="mb-8">
              <p className="text-sm mb-2 text-gray-400 w-2/3">Not owned yet, claim it to set the first tint.</p>
              {/* <button className="px-12 btn border border-black shadow cursor-pointer"> Set color & Mint </button> */}
            </div>
            }
            <div className="mb-8 mt-5 h-1 bg-gray-200 w-full md:w-1/2 rounded-sm"/>


            {/*
          OWNER INFO
          */}
            <p className="text-sm mb-2 leading-4 text-gray-700">OWNER:</p>
            {pixel.owner ? <p className="text-sm flex mb-6 max-w-max break-all w-2/3">
              {pixel.owner}
            </p> : (
              <div>
                <p className="text-sm mb-2 text-grayf-400 w-2/3">Not owned yet, claim it</p>
                {/* <button className="px-12 btn border border-black shadow cursor-pointer"> Claim PIXEL </button> */}
              </div>
            )}


            <div className="mb-8 mt-5 h-1 bg-gray-200 w-full md:w-1/2 rounded-sm"/>

            {/*
          LESSEE INFO
          */}
            {lesseeData?.lessee && parseInt(lesseeData?.lessee.split("0x")[1]) !== 0 && <div>
                <p className="text-sm mb-2 leading-4 text-gray-700">LESSEE:</p>
              {lesseeData?.lessee && lesseeData?.endBlockTime > blockTime ?
                <p className="text-sm flex mb-6 max-w-max break-all w-2/3">
                  {lesseeData?.lessee}
                </p> : (
                  <div>
                    <p className="text-sm mb-2 text-grayf-400 w-2/3">Not rented</p>
                    {/* <button className="px-12 btn border border-black shadow cursor-pointer"> Claim PIXEL </button> */}
                  </div>
                )}
              {lesseeData?.endBlockTime > blockTime && <>
                  <p className={"text-sm text-gray-500"}>Remaining time:</p>
                  <p>{remainingLeassingTime ? remainingLeassingTime : "-- : -- : --"}</p>
                  <div className="my-8 h-1 bg-gray-200 w-1/2 rounded-sm"/>
              </>}
            </div>
            }


            {/*
          CLAIM COLOR
          */}

            {
              !pixel.owner &&
                <div className="relative w-full">
                    <button
                        className={cx("w-full md:w-auto px-10 py-2 border-2 border-black cursor-default hover:shadow cursor-pointer")}
                        onClick={() => {
                          ReactGA.event({
                            category: "COLOR",
                            action: "CLAIM COLOR SELECTION",
                            label: pixel.positionX + ":" + pixel.positionY, // optional
                          });
                          setViewColorSetter(!viewColorSetter);
                        }}
                    >
                        Claim this COLOR
                    </button>
                    <p className="text-xs text-gray-500 mt-1">*Only one mint per wallet</p>
                </div>
            }
          </div>
        </section>


        {/*RIGHT BAR*/}
        <section
          className={"md:absolute md:right-0 md:top-20 md:w-1/5 w-full md:border-l-2 md:border-gray-200 md:h-4/5 flex flex-col"}>

          <div className="px-6 mb-2 mt-6">
            {<>
              <p className="text-sm mb-2 leading-4">COLOR:</p>
              <p className="text-sm mb-4 leading-4 text-gray-500">
                {pixel.owner ? <span> RGB: {pixel.colorR},{pixel.colorG},{pixel.colorB}</span> :
                  <span>Not owned yet</span>}
              </p>
              <button
                className={cx("p-2 w-full border-2 border-black cursor-default",
                  (lesseeData?.lessee !== account && pixel.owner !== account)
                    // || lesseeData?.endBlockTime < blockTime
                    ? "opacity-30" : "cursor-pointer hover:shadow")}
                onClick={() => {
                  (pixel.owner === account ||
                    (lesseeData?.lessee === account && lesseeData?.endBlockTime > blockTime)
                  ) && setViewColorChanger(!viewColorChanger);
                }}
              >
                Change color
              </button>
            </>}
          </div>

          <div className="px-6 mt-4">
            {<>
              <p className="text-sm mb-2 leading-4">RENT COLOR:</p>
              {/*SHOW BUTTON OF RENT IF ON RENT*/}
              {
                !rentingData?.isRenting && <button
                      className={cx("p-2 w-full border-2 border-black cursor-default relative",
                        (!pixel.owner || pixel.owner !== account) ? "opacity-30" : "cursor-pointer hover:shadow")}
                      onClick={() => {
                        pixel.owner === account && setIsRentingVisible(!isRentingVisible);
                      }}
                  >
                      Start Renting
                  </button>
              }

              {

                rentingData?.isRenting && pixel.owner === account && <>
                      <button
                          className={cx("px-2 py-2 mb-2 border-2 border-black cursor-default w-full cursor-pointer hover:shadow")}
                          onClick={() => {
                            pixel.owner === account && setIsRentingVisible(!isRentingVisible);
                          }}
                      >
                          Open rent settings
                      </button>
                  </>
              }

              {/*SHOW RENTING INFORMATION*/}

              {rentingData && <div className={"flex flex-col mt-2"}>
                {pixel.owner === account && <p className="text-sm text-gray-500 my-2">RENT SETTINGS:</p>}
                {<p className="text-xs text-gray-500 mb-2">{rentingData?.isRenting ? "On Rent" : "Not renting"}</p>}
                {rentingData?.isRenting && rentingData?.prices.length > 0 &&
                    <p className="text-xs text-gray-500 mb-2">Rent slots available</p>}
                {(rentingData?.isRenting || pixel.owner === account) && rentingData?.times.map((t, i) => {
                  return <>
                    <div
                      onClick={() => {
                        pixel.owner !== account && setSelectedSlot(i)
                      }}
                      className={cx(
                        "flex justify-center w-full border border-black " +
                        "px-2 py-2 flex mb-1 last:m-0",
                        selectedSlot === i && "bg-gray-200",
                        pixel.owner !== account && "cursor-pointer"
                      )}>
                      <p className={cx(
                        "text-xs text-black",
                        // selectedSlot === i ? "text-black" : "text-gray-500"
                      )}>{t} days - {rentingData?.prices[i]}<span
                        className={"text-xs text-gray-500 ml-1"}>Ξ</span></p>
                    </div>
                  </>
                })}


                  <p className="text-xs text-gray-500 my-2">Select a slot to rent:</p>
                {(rentingData?.isRenting || pixel.owner === account) &&
                  rentingData?.prices.length >= 0 &&
                  pixel.owner !== account &&
                    <>
                      {/*<p className="text-xs  my-2">Will be rented by:</p>*/}
                        <div className={"mb-2"}>

                            <p className="text-sm text-gray-500">
                                Rent duration
                            </p>
                            <p className="text-sm">
                              {rentingData.times[selectedSlot]} {rentingData.times[selectedSlot] > 1 ? "days" : "day"}
                            </p>
                        </div>
                        <div className={"mb-2"}>
                            <p className="text-xs text-gray-500">
                                Rent price:
                            </p>
                            <p className="text-xs">{
                              (rentingData.prices[selectedSlot]) || 0}<span
                                className={"text-gray-500 mx-1"}>Ξ - Rent price</span>
                            </p>
                        </div>

                        <div className={"mb-2"}>
                            <p className="text-xs">
                                <span className={"text-gray-500"}>5% creator's fee - 🍺</span>
                            </p>
                            <p className="text-xs">
                              {(rentingData.prices[selectedSlot] / 20) || 0}<span
                                className={"text-gray-500 mx-1"}>Ξ</span>

                            </p>
                        </div>

                        <div className={"mb-2"}>
                            <p className="text-sm">
                                <span className={"text-gray-500"}>Total</span>
                            </p>
                            <p className="">
                              {(rentingData.prices[selectedSlot] + (rentingData.prices[selectedSlot] / 20)) || 0}
                                <span className={"text-gray-500 mx-1"}>Ξ</span>
                            </p>
                        </div>
                    </>}

              </div>}

              {pixel.owner !== account && rentingData?.isRenting && <button
                  className={cx("p-2 mt-6 w-full border-2 border-black cursor-default relative hover:shadow",
                    pixel.owner !== account && "cursor-pointer")}
                  onClick={() => {
                    // setViewColorRent(!viewColorRent);
                    rentColor();
                  }}
              >
                  Rent COLOR
              </button>}
            </>}
          </div>

        </section>

      </article>
    </>
  )
}

PixelPage.defaultProps = {}

export default PixelPage
