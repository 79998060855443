import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {getColorsContractProvider} from "../../features/contract/contractReducers";
import cx from "classnames";
import Switch from '../Switch/Switch';
import styles from "./rentSettings.module.scss"


type IRentSettingsProps = {
  action?: any;
  close?: any;
  isRenting?: boolean;
  pricesData?: [];
  durationData?: [];
  isDisabled?: boolean;
}

const defaultProps = {}

const RentSettings: React.FC<IRentSettingsProps> = (props) => {
  const {action, close, isDisabled, isRenting, pricesData, durationData} = props;

  const colorsContract = useSelector(getColorsContractProvider)

  const getDaysFromSeconds = (seconds: number): number => {
    return seconds / 60 / 24 / 60;
  }

  const dia = 86400;
  const testTimes = [dia, dia * 10, dia * 100, dia * 1000, dia * 10000];


  const [localPrices, setLocalPrices] = useState([0.01, 0.1, 1, 1.5, 10]);
  const [localTimes, setLocalTimes] = useState<number[]>([]);
  const [isLocalRentingStatus, setIsLocalRentingStatus] = useState(false);

  const [newDuration, setNewDuration] = useState<any>(0);
  const [newPrice, setNewPrice] = useState<any>(0);

  const loadData = async () => {

    // const times = await colorsContract.getTimes();
    const newTimes = durationData && durationData.map((time): any => {
      return getDaysFromSeconds(time);
    })

    // LOAD LOCAL RENTING DATA based on blockchain if it exist
    setLocalTimes(durationData ? durationData : testTimes);
    setIsLocalRentingStatus(isRenting ? isRenting : false);
    pricesData && setLocalPrices(pricesData)
  }

  const isRentingChecked = (event: any) => {
    setIsLocalRentingStatus(event)
  }

  const removeItem = (index: number) => {
    console.log(index);
    let newTimes = [...localTimes]
    let newPrices = [...localPrices]
    if (index > -1) {
      newTimes.splice(index, 1);
      newPrices.splice(index, 1);
    }
    setLocalPrices(newPrices);
    setLocalTimes(newTimes);
  }

  const addSlot = () => {
    if (localTimes.length === 5) {
      return;
    }
    let newTimes = [...localTimes]
    let newPrices = [...localPrices]
    newTimes.push(newDuration)
    newPrices.push(newPrice)
    setLocalPrices(newPrices.map(p => parseFloat(p.toString())));
    setLocalTimes(newTimes.map(t => parseInt(t.toString())));
    setNewPrice(0)
    setNewDuration(0)
  }

  useEffect(() => {
    loadData();

    console.log(localPrices, localTimes);
  }, [colorsContract, isRenting, pricesData, durationData]);

  return (
    <section className={"flex h-full p-3 border-2 border-black z-30 flex-col justify-between"}>
      <div>

        {/*CLOSE X*/}
        <div className="absolute right-1 top-0">
          <button
            onClick={() => {
              close()
            }}
            className={"p-2"}
          >
            X
          </button>
        </div>
        <h3 className={"pb-2 font-semibold"}>RENTING SETTINGS:</h3>


        {/*
        1. SET RENTING STATUS
        */}
        <div className={"mb-2"}>
          <p>1. Set [ renting ] status</p>
          <p className="text-xs mb-2 text-gray-500">This value dictates if the COLOR could be rented by other person,
            even if price/durations are settled, if renting is switched off it would be possible to be rented. </p>
        </div>
        <div className={"flex items-center mb-4"}>
          <div className={"mr-2"}>
            <Switch
              isChecked={isLocalRentingStatus}
              handleChangeChk={isRentingChecked}/>
          </div>
          <p>{isLocalRentingStatus ? "On Rent" : "Not renting"}</p>
        </div>


        {/*
        2 SENT PRICE / DURATION SLOTS
        */}
        <div className={"mb-2"}>
          <p>2. Set [ duration | price ] slots</p>
          <p className="text-xs mb-2 text-gray-500">
            A max of five renting slots are possible. A renting slot is a possibility of price/duration you predefined
            to let others rent your COLOR. A soon as is settled, others will be able to rent your COLOR. When the
            duration ends, the COLOR will be available again for others to rent on those terms unless you set it out out Renting.
          </p>
        </div>


        <div className={"mb-12 pt-6"}>
          <div className={"flex"}>
            {/*EMPTY OR ADD NEW PRICE SLOT*/}
            <form
              className={cx(
                "flex flex-col border w-20 h-28 justify-between mr-2 last:m-0 relative"
              )}
              onSubmit={(event) => {
                addSlot();
                event.preventDefault();
              }}
            >
              <p className="absolute left-0 -top-5 text-xs mb-1 text-gray-500">Set slot</p>

              <label className={"flex flex-col justify-center items-center h-1/2 border-b"}>
                <p className={"text-xs text-gray-500"}>Duration:</p>
                <input
                  className={
                    cx("flex justify-center items-center w-full" +
                    " px-2 mx-2 text-center text-xs", styles.inputFormat)
                  }
                  type={'number'}
                  min="1"
                  max="365"
                  onChange={e => setNewDuration(e.target.value)}
                  value={newDuration}
                />
              </label>
              <label
                className={"flex flex-col justify-center items-center h-1/2"}
              >
                <p className={"text-xs text-gray-500"}>Price:</p>
                <input
                  className={cx("flex justify-center items-center w-full px-2 mx-2 text-center text-xs", styles.inputFormat)}
                  type={'number'}
                  min="0"
                  step="0.0000001"
                  onChange={e => {
                    const price = parseFloat(e.target.value);
                    setNewPrice(e.target.value)
                  }}
                  value={newPrice}
                />
              </label>
              {localTimes.length < 5 && <input
                  className={cx(
                    "border px-2 py border-black rounded-xl text-xs ",
                    styles.removeElement
                  )}
                  type="submit"
                  value={localTimes.length > 0 ? "Add" : "Create slot"}
              />}
            </form>

            {/*SEPARATOR*/}
            <div className={"w-px h-28 flex items-center justify-center"}>
              <div className={"h-1/2 w-full bg-gray-300 rounded"}/>
            </div>

            {/*SHOW SLOTS*/}
            <div className={"flex ml-2 relative w-full"}>
              <p className="absolute w-full left-0 -top-5 text-xs mb-1 text-gray-500">Slots: Duration ( days ) / Price ( Ξ )</p>
              {localTimes.map((time, i) => {
                return <>
                  <div
                    className={"flex flex-col border border-black w-20 h-28 justify-center mr-2 last:m-0 relative"}>

                    <div
                      className={
                        cx(
                          "border px-2 py bg-gray rounded-xl text-xs text-gray-500 " +
                          "hover:border-black hover:text-black",
                          styles.removeElement
                        )}
                      onClick={() => {
                        removeItem(i)
                      }}
                    >
                      remove
                    </div>

                    <div className={"flex justify-center items-center h-1/2 border-b"}>
                      <p className={"flex justify-center items-center w-full text-center text-xs"}>
                        {time}
                        <span className={"text-xs ml-1 text-gray-500"}>Days</span>
                      </p>
                    </div>

                    <div className={"flex flex-col justify-center items-center h-1/2 border-b"}>
                      <p
                        className={"flex justify-center items-center w-full px-2 mx-2 text-center text-xs"}
                      >{localPrices[i]}
                        <span className={"text-xs text-gray-500 ml-1 text-xs"}>Ξ</span></p>

                    </div>
                  </div>
                </>
              })
              }
            </div>

          </div>
        </div>


        {/*
        PREVIEW AND CONFIRM YOUR SELECTED SETTINGS
      */}
        <div className={"mb-2"}>
          <p>3. Preview & confirm your selected renting settings</p>
        </div>
        <div>
          <p className="text-xs mb-2 text-gray-500">Will the COLOR be put on-rent?: <span
            className="text-sm text-black font-semibold ">{isLocalRentingStatus ? 'YES' : 'NO'}</span>
          </p>
          <p className="text-xs mb-2 text-gray-500">
            Your renting slots: <span>{localTimes.length}/5</span>
          </p>
          <div className={"flex"}>
            {localTimes.map((t, i) => {
              return <>
                <div className="bg-gray-200 rounded-xl px-2 py-1 flex mr-1 last:m-0">
                  <p className="text-xs text-gray-500">{t} days - {localPrices[i]}<span
                    className={"text-xs text-gray-500 ml-1"}>Ξ</span></p>
                </div>
              </>
            })}
          </div>
        </div>

      </div>


      <div>
        <p className="text-xs pb-1 text-right text-gray-500">Nothing is settle until you confirm your renting settings,
          your wallet will be prompt.*</p>
        <button
          className={cx("px-10 py-2 border-2 border-black cursor-default w-full",
            isDisabled ? "opacity-30" : "cursor-pointer shadow")}
          onClick={() => {
            console.log(
              isLocalRentingStatus,
              localPrices,
              localTimes
            );
            action(
              {
                isRenting: isLocalRentingStatus,
                prices: localPrices.sort((a, b) =>{
                  return localTimes.indexOf(a) - localTimes.indexOf(b);
                }),
                durations: localTimes.sort((a, b) =>{
                  return a - b;
                })
              }
            );
          }}
        >
          Confirm settings
        </button>
      </div>
    </section>
  )
}

RentSettings.defaultProps = defaultProps;

export default RentSettings
