import React, {useState, useEffect} from 'react';
import {Dapp} from "./Dapp.js"
import About from "./About"
import styles from "./root.module.scss"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link, Redirect
} from "react-router-dom";
import NavBar from "./Navbar/NavBar";
import Pixels from "./Pixels/Pixels";
import PixelPage from "./PixelPage/PixelPage";
import {useDispatch, useSelector} from "react-redux";
import {isProvider} from "../features/account/accountActions";
import Home from './Home/Home';
import {getColorsContractProvider} from "../features/contract/contractReducers";
import Dataloader from "./DataLoader/DataLoader";
import TokenRenderer from './TokenRenderer/TokenRenderer';
import MainWeb3Wrapper from "./Web3Provider/Main/MainWeb3Wrapper";
import TxUi from './TxUi/TxUi';
import Stills from "./Stills/Stills";
import MyColor from "./MyCOLOR/MyColor";
import contractAddresses from "../contracts/old-contracts-address.json";
import HowItWorks from "./HotItWorks";
import Footer from "./Footer";
import {BuildHelmet} from "./HelmetBuilder";
import metaImage from "../assets/img/RRSS_MARCO.png"
import ReactGA from "react-ga4";

const ThemeContext = React.createContext('light');

type IRootContainerProps = {}

const RootContainer: React.FC<IRootContainerProps> = (props) => {

  const dispatch = useDispatch()
  const contract = useSelector(getColorsContractProvider)
  // const isProvider = useSelector()

  useEffect(() => {
    ReactGA.initialize("G-JH5CZE1S2K");

    dispatch(isProvider())
  }, []);

  return (
    <React.Fragment>
      <div className={styles.baseContainer}>

        <BuildHelmet
          title={"MARCO | Collaborative Art"}
          description={"Marco is an eternal, interactive & collaborative art project, and economic experiment built fully on-chain on the ethereum blockchain."}
          facebookImg={
            // metaImage
            "/RRSS_MARCO.png"
          }
          linkedInImg={metaImage}
          twitterImg={
            // metaImage
            "/RRSS_MARCO.png"
          }
        />

        <Router>
          <MainWeb3Wrapper>
            <Dataloader>

              <TxUi/>
              <NavBar/>

              <Switch>
                {/*<Redirect exact from="/" to="/pixels"/>*/}
                <Route path="/stills">
                  <Stills/>
                </Route>
                <Route path="/how">
                  <HowItWorks/>
                </Route>
                <Route path="/pixels">
                  <Pixels/>
                </Route>
                <Route path="/account">
                  <MyColor/>
                </Route>
                <Route path="/about">
                  <About/>
                </Route>
    {/*            <Route path="/test">
                  <Test/>
                </Route>*/}
                <Route path="/tokenuri/:id">
                  <TokenRenderer/>
                </Route>
                <Route path="/color/:id">
                  <PixelPage/>
                </Route>
                <Route path="/">
                  <Pixels/>
                </Route>
              </Switch>

              {/*BOTTOM PANEL*/}
              <Footer/>

            </Dataloader>
          </MainWeb3Wrapper>
        </Router>
      </div>
    </React.Fragment>
  )
}

RootContainer.defaultProps = {}

export default RootContainer
