import React, {useEffect, useState} from 'react';
import {getPixels, IPixel} from "../../features/pixels/pixelsReducers";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import styles from "./pixelsBuilder.module.scss"
import useMouse from "@react-hook/mouse-position";
import Pixelstwo from "../PixelsTwo/PixelsTwo";
import PixelsSvg from "./PixelsSvg";
import cx from "classnames"
import {useWeb3React} from "@web3-react/core";
import {getColorsContractProvider} from "../../features/contract/contractReducers";

export type PixelsbuilderProps = {
  onMouseEnter?: any
}

const Pixelsbuilder: React.FC<PixelsbuilderProps> = (props) => {
  const allPixels = useSelector(getPixels);
  const history = useHistory();
  const colorsContract = useSelector(getColorsContractProvider);


  const {account} = useWeb3React();

  const navigate = (id: string, params: any) => {
    console.log("SEND => ", params)
    history.push("pixel/" + id, {params});
  }

  const [selectedPixel, setSelectedPixel] = useState<IPixel>();
  const [isSaleActive, setIsSaleActive] = useState(false);
  const [maxColors, setMaxColors] = useState(0);

  const ref = React.useRef(null);

  const mouse = useMouse(ref, {
    enterDelay: 0,
    leaveDelay: 0,
    fps: 60
  });

  const loadData = async () => {
    if (!colorsContract) {
      return
    }
    const isActive = await colorsContract.isSaleActive();
    console.log("IS_SALE_ACTIVE => ", isActive);
    setIsSaleActive(isActive);

    const maxColors = await colorsContract.totalSupply();
    console.log("ACTUAL_COLORS => ", maxColors.toString());
    setMaxColors(maxColors.toString());
  }

  useEffect(() => {
    loadData();
  }, [colorsContract]);

  const buildHoverPixel = () => {
    return <div className={"hidden md:flex"}>
      {mouse.x && mouse.y && mouse.x > 0 && (mouse.y) > 0 ?
      <div
          className={cx(styles.hoverBox)}
          style={{
            left: mouse.x ? mouse.x + 20 : 0,
            top: mouse.y && mouse.y < 400 ? mouse.y + 20 : mouse.y - 180
          }}>
          <div
              className={styles.selectedPixel}
              style={{
                backgroundColor: "rgb(" +
                  selectedPixel?.colorR + "," +
                  selectedPixel?.colorG + "," +
                  selectedPixel?.colorB + ")"
              }}>
          </div>
          <div className={cx(styles.textContainer)}>
              <div>
                  <p className="text-xs text-gray-700 mb-2">ID:{selectedPixel?.tokenId ? selectedPixel?.tokenId : "Click to claim"}</p>
                {/*<p>ID:</p>*/}

                  <p className="text-xs text-gray-700">POSITION:</p>
                  <p className="text-sm font-semibold mb-2"><span
                      className="text-sm font-normal text-gray-700">X</span>{selectedPixel?.positionX}:{selectedPixel?.positionY}<span
                    className="text-sm font-normal text-gray-700">Y</span>
                  </p>

                  <p className="text-xs text-gray-700 mb-1">COLOR:</p>
                  <div>
                    {selectedPixel?.isMinted ?
                      <p className="text-xs font-semibold leading-3">RGB: {selectedPixel?.colorR}, {selectedPixel?.colorG}, {selectedPixel?.colorB}</p> :
                      <p className="text-xs font-semibold leading-3">NOT CLAIMED YET</p>
                    }
                  </div>

              </div>
              <p className="text-xs text-gray-700">{!selectedPixel?.isMinted ? "Click on the COLOR to claim" : "Click on the COLOR"}</p>
          </div>
      </div> : <></>
      }
    </div>

  }

  return (
    <>
      <div ref={ref} className={styles.baseContainer}>
        {buildHoverPixel()}
        {maxColors <= 9999 && <p className="text-xs z-40 absolute -top-5 left-2 text-gray-400">Select & Mint your COLOR</p>}
        {!account && <div className="border border-black p-4 bg-white rounded-2xl absolute top-2 right-2 z-40 w-48 text-right">
            <p className="text-xs">The art piece data is loaded directly from ethereum blockchain, connect a wallet:</p>
            <p className="text-xs text-gray-500 z-40">eg: <a className={"underline"} href={"http://metamask.io"}>metamask*</a></p>
        </div>}
        {!isSaleActive && account && <p className="text-sm z-40 border border-black px-6 py-1 bg-white rounded-2xl absolute top-2 right-2">Sale not active</p>}
        <PixelsSvg allPixels={allPixels} onMouseEnter={setSelectedPixel}/>
      </div>
    </>
  );
}
export default React.memo(Pixelsbuilder);
