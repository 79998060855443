import React, {useState, useEffect} from 'react';
import cx from "classnames";
import {IPixel} from "../../features/pixels/pixelsReducers";
import {ethers} from "ethers";
import {useSelector} from "react-redux";
import {getColorsContractProvider, getRentContractProvider} from "../../features/contract/contractReducers";
import {useWeb3React} from "@web3-react/core";
import styles from "./account.module.scss";
import {dispatch} from "../../store/store";
import {submitPayableTx} from "../../features/transaction/transactionActions";
import {Link} from "react-router-dom";
import {Account} from "../Web3Provider/DataLoaders/dataLoaders";

type IMyColorProps = {}

const defaultProps = {}

const MyColor: React.FC<IMyColorProps> = (props) => {
  const {} = props;
  const colorsContract = useSelector(getColorsContractProvider);
  const rentContract = useSelector(getRentContractProvider);
  const [pixel, setPixel] = useState<IPixel>({
    positionX: 0,
    positionY: 0,
  });

  const {account, library} = useWeb3React();

  const [rentingData, setRentingData] = useState<{
    isRenting: boolean,
    prices: [],
    times: []
  }>();

  // RENTER
  const [lesseeData, setLesseeData] = useState<{
    lessee: string;
    endBlockTime: any
  }>();


  const [proceedings, setProceedings] = useState<any>(0);
  const [mainOwner, setMainOwner] = useState("");

  const [svg, setSvg] = useState<any>();

  const loadData = async () => {

    let pixelData;
    // console.log("ALL PIXELS PIXEL PAGE => ", allPixels);    // Check if ethers provider is connected
    if (!colorsContract) {
      // alert("Connect Metamask");
      return;
    }
    const mainOwner = await colorsContract.owner();
    setMainOwner(mainOwner.toString())

    const ownerTokenId = await colorsContract.tokenOfOwnerByIndex(account, 0);
    const tokenId = parseInt(ownerTokenId.toString());

    // Check if the pixel exist already
    let selectedPixel: IPixel = {positionX: 0, positionY: 0};

    console.log("REMOTE TOKENID => ", tokenId)

    // GET COLOR DETAIL DATA
    pixelData = await colorsContract.getCOLORData(tokenId);

    console.log("LOAD BLOCKCHAIN PIXEL", pixelData);

    if (pixelData) {
      selectedPixel.tokenId = tokenId;
      selectedPixel.colorR = pixelData[0];
      selectedPixel.colorG = pixelData[1];
      selectedPixel.colorB = pixelData[2];
      selectedPixel.positionX = pixelData[3]
      selectedPixel.positionY = pixelData[4]
      selectedPixel.owner = pixelData[5];
    }

    console.log("SELECTED BLOCKCHAIN PIXEL", selectedPixel);
    setPixel(selectedPixel);

    const rentInfo = await rentContract.getColorRentingData(tokenId);
    console.log("RENTING DATA", rentInfo);

    const rentingData = rentInfo[0];
    const rentedData = rentInfo[1];

    const endBlockTime = rentedData['endLeaseTimeStamp'];
    setLesseeData({
      lessee: rentedData['lessee'],
      endBlockTime: endBlockTime && endBlockTime.toString(),
    })

    const isRenting = rentingData['isOnRent'];
    const prices = rentingData['prices'].map((price: ethers.BigNumberish) => {
      return ethers.utils.formatEther(price);
    });
    const times = rentingData['durations'];
    setRentingData({
      isRenting: isRenting,
      times: times.map((t: ethers.BigNumber) => parseFloat(t.toString()) / 60 / 60 / 24),
      prices: prices.map((p: any) => parseFloat(p.toString())),
    })

    const rentPayments = await rentContract.payments(account);
    console.log("PAYMENTS", rentPayments.toString());
    setProceedings(ethers.utils.formatEther(rentPayments.toString()))

    // BLOCK TIME
    /*    if (library) {
          const blockTime = await library.getBlock('latest');
          setBlockTime(blockTime.timestamp);
        }*/
  }

  const redeemRent = async () => {
    dispatch(submitPayableTx(
      {
        functionName: "withdrawPayments",
        valueArgs: [
          account,
        ],
        overrides: {},
        name: "Withdraw rent income",
        contract: rentContract
      }
    ));
    // changeColorRentVisibility();
  }

  const redeemAll = async () => {
    dispatch(submitPayableTx(
      {
        functionName: "withdrawAll",
        valueArgs: [],
        overrides: {},
        name: "All",
        contract: colorsContract
      }
    ));
    // changeColorRentVisibility();
  }
  const buildSvg = () => {
    if (pixel.colorR !== undefined && pixel.colorG !== undefined && pixel.colorB !== undefined) {

      let color = "rgb(" + pixel.colorR + "," + pixel.colorG + "," + pixel.colorB + ")";

      let sc_R = pixel.colorG,
        sc_G = pixel.colorB,
        sc_B = pixel.colorR;

      let pc_R = pixel.colorR,
        pc_G = pixel.colorG,
        pc_B = pixel.colorB;

      if (
        pc_R < 127 &&
        pc_G < 127 &&
        pc_B < 127
      ) {
        pc_R = (255 - pc_R) / 3
        pc_G = (255 - pc_G) / 3
        pc_B = (255 - pc_B) / 3
      }

      if (
        pc_R >= 127 &&
        pc_G >= 127 &&
        pc_B >= 127
      ) {
        pc_R = pc_R / 3
        pc_G = pc_G / 3
        pc_B = pc_B / 3
      } else {
        pc_R = pc_R / 1.5
        pc_G = pc_G / 1.5
        pc_B = pc_B / 1.5
      }

      // TOO DARK, MAKE IT LIGHTER
      if (
        sc_R < 127 &&
        sc_G < 127 &&
        sc_B < 127
      ) {
        sc_R = (255 - sc_R) / 3
        sc_G = (255 - sc_G) / 3
        sc_B = (255 - sc_B) / 3
      }

      // TOO LIGHT, MAKE IT DARKER
      if (
        sc_R >= 127 &&
        sc_G >= 127 &&
        sc_B >= 127
      ) {
        sc_R = sc_R / 3
        sc_G = sc_G / 3
        sc_B = sc_B / 3
      }
      let colorSC = "rgb(" + sc_R + "," + sc_G + "," + sc_B + ")";

      const svg = <svg
        xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 100 100"
      >
        <rect
          x="0" y="0"
          width="100" height="100"
          fill={color}/>

        <text
          font-weight="bold"
          text-anchor="middle"
          dominant-baseline="middle"
          transform="matrix(1.7 0 0 1.7 50 52)"
              fill={colorSC}
        >
          {pixel.positionX}:{pixel.positionY}
        </text>

      </svg>
      setSvg(svg)
    }
  }

  useEffect(() => {
    buildSvg();

    loadData();
  }, [
    colorsContract, pixel
  ]);


  return (
    <div className={"flex flex-col md:flex-row md:block md:justify-end items-center pt-24 w-full"}>

      {/*RIGHT BAR*/}
        {
          pixel.owner && <div className={styles.selectedPixel}>
            {svg}
            </div>
        }
      <section className={"md:absolute right-0 top-20 w-full md:w-1/4 md:h-4/5 flex flex-col text-right"}>

        {/*border-l-2 border-gray-200*/}
        <div className="px-6 mb-2 mt-6 md:pr-16">
          {<>
            <div className="mb-4 relative">
              <p className="text-sm mb-4 leading-4 text-gray-500">ADDRESS:</p>
                <Account/>
            </div>

            <p className="text-sm mb-4 leading-4 text-gray-500">ID:</p>
            <p className="text-3xl mb-4 leading-4">
              <span className="text-gray-400">#</span>{pixel.tokenId}
            </p>

            <p className="text-sm mb-2 leading-4 text-gray-500">POSITION:</p>

            <p className="text-3xl mb-4">
              <span className="text-gray-400">X</span>
              {pixel.positionX}:{pixel.positionY}
              <span className="text-gray-400">Y</span>
            </p>

            <p className="text-sm mb-2 leading-4 text-gray-500">COLOR:</p>
            <p className="text-sm mb-4 leading-4 text-gray-500">
              RGB:<span className={"text-black text-3xl"}> {pixel.colorR},{pixel.colorG},{pixel.colorB}</span>
            </p>

          </>}
          <Link to={"color/" + pixel.positionX + "_" + pixel.positionY}>
            <button
              className={cx("px-6 py-2 w-full border-2 border-black cursor-default relative",
                "cursor-pointer hover:shadow")}
            >
              COLOR Details
            </button>
          </Link>
        </div>

        <div className="px-6 mt-4 md:pr-16">
          {<>
            <p className="text-sm mb-4 leading-4">RENT INCOME:</p>
            <p className="text-xl mb-6 leading-4">
              {proceedings} Ξ
            </p>
            {
              <button
                className={cx("p-2 w-full border-2 border-black cursor-default relative",
                  proceedings > 0 ? "cursor-pointer hover:shadow" : "opacity-30")}
                onClick={() => {
                  proceedings > 0 && redeemRent()
                }}
              >
                Reedeem
              </button>
            }
          </>}
        </div>

        {
          /*      // account === mainOwner &&
                  <div className="px-6 mt-4">
                {<>
                  <p className="text-sm mb-4 leading-4">REDEEM:</p>
                  <p className="text-xl mb-6 leading-4">
                    {mainOwner}
                  </p>
                  {
                    <button
                      className={cx("p-2 w-full border-2 border-black cursor-default relative",
                        proceedings > 0 ? "cursor-pointer hover:shadow" : "opacity-30")}
                      onClick={() => {
                        // account === mainOwner &&
                        redeemAll()
                      }}
                    >
                      Reedeem All
                    </button>
                  }
                </>}
              </div>*/
        }

      </section>


    </div>
  )
}

MyColor.defaultProps = defaultProps;

export default MyColor
