import React, {useEffect, useState} from 'react';
import {getPixels, IPixel} from "../../features/pixels/pixelsReducers";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import styles from "./pixelsBuilderStill.module.scss"
import useMouse from "@react-hook/mouse-position";
import cx from "classnames"
import {useWeb3React} from "@web3-react/core";
import PixelsSvgStill from './PixelsSvgStill';

export type PixelsbuilderProps = {
  onMouseEnter?: any
}

const PixelsBuilderStill: React.FC<PixelsbuilderProps> = (props) => {
  let allPixels = useSelector(getPixels);
  const history = useHistory();
  allPixels = [];
  const {connector, active, account, library, activate, deactivate} = useWeb3React();

  const navigate = (id: string, params: any) => {
    console.log("SEND => ", params)
    history.push("pixel/" + id, {params});
  }

  const [selectedPixel, setSelectedPixel] = useState<IPixel>();

  const ref = React.useRef(null);
  const canvasRef = React.useRef(null);

  const mouse = useMouse(ref, {
    enterDelay: 0,
    leaveDelay: 0,
    fps: 60
  })

  useEffect(() => {
    // buildCANVAS();
  }, []);

  const buildHoverPixel = () => {
    return <>
      {mouse.x && mouse.y && mouse.x > 0 && (mouse.y) > 0 &&
      <div
          className={cx(styles.hoverBox)}
          style={{
            left: mouse.x ? mouse.x + 20 : 0,
            top: mouse.y && mouse.y < 400 ? mouse.y + 20 : mouse.y - 180
          }}>
          <div
              className={styles.selectedPixel}
              style={{
                backgroundColor: "rgb(" +
                  selectedPixel?.colorR + "," +
                  selectedPixel?.colorG + "," +
                  selectedPixel?.colorB + ")"
              }}>
          </div>
          <div className={cx(styles.textContainer)}>
              <div>
                  <p className="text-xs text-gray-700 mb-2">ID:{selectedPixel?.tokenId ? selectedPixel?.tokenId : "Click to claim"}</p>
                {/*<p>ID:</p>*/}

                  <p className="text-xs text-gray-700">POSITION:</p>
                  <p className="text-sm font-semibold mb-2"><span
                      className="text-sm font-normal text-gray-700">X</span>{selectedPixel?.positionX}:{selectedPixel?.positionY}<span
                    className="text-sm font-normal text-gray-700">Y</span>
                  </p>

                  <p className="text-xs text-gray-700 mb-1">COLOR:</p>
                  <div>
                    {selectedPixel?.isMinted ?
                      <p className="text-xs font-semibold leading-3">RGB: {selectedPixel?.colorR}, {selectedPixel?.colorG}, {selectedPixel?.colorB}</p> :
                      <p className="text-xs font-semibold leading-3">NOT CLAIMED YET</p>
                    }
                  </div>

              </div>
              <p className="text-xs text-gray-700">{!selectedPixel?.isMinted ? "Click on the COLOR to claim" : "Click on the COLOR"}</p>
          </div>
      </div>
      }
    </>

  }

  return (
    <>
      <div ref={ref} className={styles.baseContainer}>
        {!account && <p className="text-sm z-50 border border-black px-6 py-1 bg-white rounded-2xl absolute top-2 right-2">Connect metamask*</p>}
        <PixelsSvgStill allPixels={allPixels} onMouseEnter={setSelectedPixel}/>

      </div>
    </>
  );
}
export default React.memo(PixelsBuilderStill);
