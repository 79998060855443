import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {getPixels, IPixel} from "../../features/pixels/pixelsReducers"; // we need this to make JSX compile

type IPixelsRemainingProps = {}

const defaultProps = {}

const PixelsRemaining = ({}: IPixelsRemainingProps) => {
  const [rPxs, setRPxs] = useState<number | null>(null);
  const allPixels = useSelector(getPixels)

  useEffect(() => {
    const rPixs = Object.entries<IPixel>(allPixels).filter(([coords, p]) => {
      return !p.isMinted
    })
    console.log(rPixs.length);
    setRPxs(rPixs.length);


    }, [allPixels]);

  return (
      <div className="flex flex-col items-center md:w-1/3 w-full justify-center">
        {rPxs !== 0 ? <>
          <p className="text-xs text-gray-500">remaining COLORS</p>
          <p className="text-4xl text-gray-700">{rPxs}</p></> : <p className="text-xs text-gray-500">All COLORS have been allocated 🎉</p>}
        {/*<p className="text-xs text-gray-500">of 10000</p>*/}
      </div>
  )
}

PixelsRemaining.defaultProps = defaultProps;

export default React.memo(PixelsRemaining)
