import {IPixel} from "../features/pixels/pixelsReducers";

export class PixelService {

  static buildInitialPixels(): { [key: string]: IPixel } {
    // const randNum = () => Math.floor(Math.random() * 256);
    let pixelsData: IPixel[] = [];
    for (let i = 0; i < 100; i++) {
      for (let j = 0; j < 100; j++) {
        // console.log('TOKENID => ',i+j);
        pixelsData.push({
          tokenId: null,
          isMinted: false,
          colorR: 255,
          colorG: 255,
          colorB: 255,
          positionX: j,
          positionY: i,
          owner: ""
        })
      }
    }

    const pixelObjects = pixelsData
      .map(({
              tokenId,
              isMinted,
              colorR,
              colorG,
              colorB,
              positionX,
              positionY,
              owner
            }) => ({
        [positionX.toString() + "_" + positionY.toString()]: {
          tokenId,
          isMinted,
          colorR,
          colorG,
          colorB,
          positionX,
          positionY,
          owner
        }
      }));

    let finalObject = {}
    pixelObjects.forEach(p=>{
      Object.assign(finalObject, p);
    });

    // console.log('NEW OBJECT PIXELS',finalObject);
    return finalObject;
  }

  static randomColor(): string {
    const randNum = () => Math.floor(Math.random() * 256);
    const color = `rgba(${randNum()},${randNum()},${randNum()}, 0.5)`
    return color
  }

  static loadRemotePixels() {

  }
}
